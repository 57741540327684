import styled from '@emotion/styled';
import { Flex, UtilityButton } from '@robinpowered/design-system';
import DragSolid from '@robinpowered/icons/DragSolid';
import TrashOutline from '@robinpowered/icons/TrashOutline';
import { useTranslation } from 'react-i18next';
import { OptionsList, RequiredToggle } from '../components';
import {
  UITicketQuestion,
  UITicketQuestionExpectingChoices,
  UITicketQuestionExpectingMenuChoices,
  isMultiChoiceQuestionType,
} from 'types';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { AddItemButton, FieldError } from 'components/ServiceForm/components';
import { QuestionBuilderProvider } from '../contexts/QuestionBuilderContext';
import { useManageQuestion } from './useManageQuestion';
import { useFormContext } from 'react-hook-form';
import { MeetingServiceType } from 'components/ServiceForm/ServiceFormContainer';
import { Input, Select } from '@robinpowered/ui-kit';
import { MultiSelectToggle } from '../components/MultiSelectToggle';
import { useScrollTo } from '../hooks/useScrollTo';
import { useFeatureFlags } from 'contexts/FeatureFlagContext';

const MAX_OPTIONS = 100;

/** UI types for controlling what the dropdown button displays */
export const QuestionDropdownOptions = [
  'short',
  'long',
  'multiple_choice',
  'menu',
  'files',
] as const;

export type QuestionDropdownTypes = (typeof QuestionDropdownOptions)[number];

type QuestionBuilderProps = {
  question: UITicketQuestion;
};

export const QuestionBuilder = ({
  question,
}: QuestionBuilderProps): JSX.Element => {
  const { t } = useTranslation('QuestionsSection');
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: question.id });
  const { formState, getValues } = useFormContext<MeetingServiceType>();

  const { scrollToQuestionErrorRef } = useScrollTo();
  const showErrorMessage =
    !!formState.errors.questions && !question.prompt.trim().length;

  const totalQuestions = getValues('questions').size;

  const {
    handleAddChoiceOption,
    handleAddMenuOption,
    handleSelectDropdown,
    handleToggleRequired,
    handleToggleMultiSelect,
    handleUpdatePrompt,
    handleDeleteQuestion,
    firstQuestionWithEmptyPromptById,
  } = useManageQuestion();

  const style = {
    transform: CSS.Translate.toString(transform),
    transition,
  };

  const { checkFlag, loading: featureFlagLoading } = useFeatureFlags();
  const shouldShowFileUpload = checkFlag('phoenix(ticket-file-uploads)', false);

  const dropdownOptions = QuestionDropdownOptions.map((option) => ({
    value: option,
    label: t(`question_types.${option}`),
  })).filter(
    (option) =>
      option.value !== 'files' || (shouldShowFileUpload && !featureFlagLoading)
  );

  return (
    <QuestionBuilderProvider question={question}>
      <Wrapper ref={setNodeRef} style={style} data-testid="question-builder">
        <QuestionWrapper
          ref={
            firstQuestionWithEmptyPromptById === question.id
              ? scrollToQuestionErrorRef
              : null
          }
        >
          <PromptWrapper>
            {totalQuestions > 1 && (
              <UtilityButton
                icon={DragSolid}
                style={{ cursor: 'move' }}
                {...attributes}
                {...listeners}
              />
            )}
            <InputWrapper>
              <Input
                name={t('input')}
                value={question.prompt}
                onChange={(e) => handleUpdatePrompt(e.target.value, question)}
                placeholder={t('input')}
                status={showErrorMessage ? 'error' : ''}
              />
              {showErrorMessage && (
                <FieldError message={t('errors.empty_prompt')} />
              )}
            </InputWrapper>
            <Select
              options={dropdownOptions}
              onSelect={(type) => {
                handleSelectDropdown(type, question);
              }}
              defaultValue={question.type}
              style={{ minWidth: '200px' }}
            />
          </PromptWrapper>
          {isMultiChoiceQuestionType(question) && (
            <>
              <OptionsList options={question.options} />
              <Divider />
              {question.options.length < MAX_OPTIONS && (
                <AddOptionWrapper>
                  <AddItemButton
                    onClick={() => {
                      if (question.type === 'multiple_choice') {
                        handleAddChoiceOption(
                          question as UITicketQuestionExpectingChoices
                        );
                      }
                      if (question.type === 'menu') {
                        handleAddMenuOption(
                          question as UITicketQuestionExpectingMenuChoices
                        );
                      }
                    }}
                    title={t('option.add.button')}
                    ariaLabel={t('option.add.label')}
                    style={{ width: '100%' }}
                  />
                </AddOptionWrapper>
              )}
            </>
          )}
        </QuestionWrapper>
        <MetaWrapper>
          <ToggleWrapper>
            {(question.type === 'menu' ||
              question.type === 'multiple_choice') && (
              <MultiSelectToggle
                onToggle={() =>
                  handleToggleMultiSelect(
                    question as UITicketQuestionExpectingChoices
                  )
                }
                checked={
                  !(question as UITicketQuestionExpectingChoices)
                    .chooseOnlyOneOption
                }
              />
            )}
            <RequiredToggle
              onToggle={() => handleToggleRequired(question)}
              checked={question.required}
            />
          </ToggleWrapper>
          <div title={t('tooltips.delete')}>
            <UtilityButton
              icon={TrashOutline}
              onClick={() => handleDeleteQuestion(question.id)}
            />
          </div>
        </MetaWrapper>
      </Wrapper>
    </QuestionBuilderProvider>
  );
};

const Wrapper = styled(Flex)`
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.colorTokens.main.white};
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.colorTokens.stroke.layer2};
`;

const PromptWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  align-items: start;
  gap: 6px;
`;

const QuestionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 16px;
`;

const InputWrapper = styled.div`
  flex: 1;
`;

const MetaWrapper = styled.div`
  display: flex;
  flex: 1;
  padding: 16px;
  gap: 24px;
  border-top: 1px solid ${({ theme }) => theme.colorTokens.stroke.layer2};
  justify-content: end;
`;

const ToggleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  margin-left: auto;
`;

const AddOptionWrapper = styled.div`
  padding-left: 25px;
`;

const Divider = styled.div`
  border-left: 1px solid ${({ theme }) => theme.colorTokens.stroke.layer2};
`;
