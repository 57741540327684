import Cookies from 'js-cookie';
import { config } from 'config';
import { useExternalAccessToken } from './useExternalAccessToken';

type RobinSession = {
  access_token: string;
};

export function useAccessToken() {
  const sessionCookie = Cookies.get(config.sessionCookieName);
  const {
    externalAccessToken,
    setExternalAccessToken,
    isExternalTokenReady,
    shouldUseParentToken,
  } = useExternalAccessToken();

  const robinSession = (sessionCookie && JSON.parse(sessionCookie)) as
    | RobinSession
    | undefined;
  // If we have the use_parent_token=true param in the url and external access token is set, we use the external access token that we get from the parent
  // Otherwise, we use the access token that we get from the useAccessToken hook
  // This is here so we do not use a different token if there is a current session from the same domain ongoing
  const accessToken =
    shouldUseParentToken && externalAccessToken
      ? externalAccessToken
      : robinSession?.access_token;

  return {
    accessToken: accessToken ?? null,
    setExternalAccessToken,
    isExternalTokenReady,
    shouldUseParentToken,
  };
}
