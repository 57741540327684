import styled from '@emotion/styled';
import { Tooltip, Typography, theme } from '@robinpowered/ui-kit';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperclip } from '@awesome.me/kit-cf858715fd/icons/classic/regular';
import { css } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import { useFileDownload } from 'components/ServiceDetailsSidebar/hooks/useFileDownload';
import { config } from 'config';
import { useAuthContext } from 'contexts/AuthContext';
import { TicketIdentifier } from 'components/ServiceDetailsSidebar/hooks/useFileDownload';

export const FileAnswer = ({
  file,
  ticketIdentifier,
}: {
  file: { id: string; name: string };
  ticketIdentifier: TicketIdentifier;
}) => {
  const { t } = useTranslation('ServiceDetailsSidebar');
  const { useToken } = theme;
  const { token } = useToken();
  const { downloadFile, downloadingFile } = useFileDownload();
  const { currentOrg } = useAuthContext();

  const downloadFileUrl = `${config.dashboardUrl}/${
    currentOrg?.slug
  }/tickets/list?serviceRequestKey=${
    'key' in ticketIdentifier ? ticketIdentifier.key : ticketIdentifier.id
  }&fileId=${file.id}`;

  return (
    <FileAnswerWrapper
      downloadingFile={downloadingFile}
      style={{
        position: 'relative',
        display: 'flex',
        flexDirection: 'row',
      }}
      key={file.id}
    >
      <FontAwesomeIcon icon={faPaperclip} />
      <Tooltip
        title={t('file_answer.download_tooltip', {
          fileName: file.name,
        })}
      >
        <Typography.Link
          strong
          style={{
            color: token.colorPrimary,
          }}
          href={downloadFileUrl}
          onClick={(e) => {
            e.preventDefault();
            downloadFile({
              fileId: file.id,
              ticketIdentifier,
              fileName: file.name,
            });
          }}
          disabled={downloadingFile}
        >
          {file.name}
        </Typography.Link>
      </Tooltip>

      {/* @TODO Skipping this until we work on editing files on the tickets page */}
      {/* <Button
        type="text"
        shape="default"
        size="middle"
        title={t('file_answer.delete_tooltip', {
          fileName: file.name,
        })}
        loading={downloadingFile}
        icon={<FontAwesomeIcon icon={faTrash} />}
        onClick={handleDeleteFile}
        style={{
          position: 'absolute',
          right: '10px',
        }}
      /> */}
    </FileAnswerWrapper>
  );
};

const FileAnswerWrapper = styled.div<{ downloadingFile: boolean }>`
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;

  ${({ downloadingFile }) =>
    downloadingFile &&
    css`
      opacity: 0.5;
      cursor: not-allowed;
    `}
`;
