import { FormProvider } from 'react-hook-form';
import { ServiceForm } from './ServiceForm';
import { UITicketQuestion } from 'types';
import { useManageServiceForm } from './useManageServiceForm';
import {
  FacilitatorGroup,
  FacilitatorUser,
} from './sections/ServiceSection/sections/usePersonGroupSearch';
import { ActionModal } from '@robinpowered/design-system';
import { Body } from '@robinpowered/design-system';
import { useTranslation } from 'react-i18next';

type Facilitator = { users: FacilitatorUser[]; groups: FacilitatorGroup[] };

type BuildingId = string;
type SpaceId = string;
export type AvailableInSpacesByBuilding = Map<
  BuildingId,
  Set<SpaceId> | undefined
>;

export type MeetingServiceType = {
  category: {
    name: string;
    key: string;
    id: string;
  };
  description: string;
  serviceName: string;
  questions: Map<string, UITicketQuestion>;
  availableInSpacesByBuilding: AvailableInSpacesByBuilding;
  assignees: Facilitator;
  approvers: Facilitator;
  currencyCode: string;
};

type Props = {
  onSuccessfulSubmit: (status: 'added' | 'updated') => void;
};

export const ServiceFormContainer = ({ onSuccessfulSubmit }: Props) => {
  const { t } = useTranslation('ServiceForm');
  const {
    methods,
    loading,
    error,
    onSubmit,
    savingMeetingService,
    hasUpdateServiceConflict,
    setHasUpdateServiceConflict,
    refetchMeetingService,
    isUpdatingMeetingService,
  } = useManageServiceForm(onSuccessfulSubmit);

  if (error) {
    return null;
  }

  return (
    <FormProvider {...methods}>
      <ActionModal
        variant="warning"
        title={t('update_modal.title')}
        cancelButtonText={t('update_modal.close')}
        confirmButtonText={t('update_modal.submit')}
        isOpen={hasUpdateServiceConflict}
        onSecondaryButtonClick={() => {
          setHasUpdateServiceConflict(false);
        }}
        onConfirm={async () => {
          await refetchMeetingService();
          setHasUpdateServiceConflict(false);
        }}
        onClose={() => {
          setHasUpdateServiceConflict(false);
        }}
      >
        <Body.Medium>{t('update_modal.body')}</Body.Medium>
      </ActionModal>

      <ServiceForm
        isUpdatingMeetingService={isUpdatingMeetingService}
        onSubmit={methods.handleSubmit(onSubmit)}
        loading={loading}
        savingMeetingService={savingMeetingService}
      />
    </FormProvider>
  );
};
