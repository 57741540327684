import { GetTicketsForCsvExportQuery } from 'generated/graphql';

export const DEFAULT_TOAST_ERROR_SECONDS = 5; // 5 seconds

export const getInitials = function (string: string) {
  if (!string) {
    return '';
  }

  const names = string.split(' ');
  let initials = names[0].substring(0, 1).toUpperCase();

  if (names.length > 1) {
    initials += names[names.length - 1].substring(0, 1).toUpperCase();
  }
  return initials;
};

type TotalPrice = {
  amountMajor: string;
  currencyCode: string;
};

export const formatCurrency = (totalPrice: TotalPrice) => {
  return new Intl.NumberFormat(navigator.language, {
    style: 'currency',
    currencyDisplay: 'narrowSymbol',
    currency: totalPrice?.currencyCode,
    // can take a string, types are off: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat/format
  }).format(totalPrice?.amountMajor as unknown as number);
};

export const mapAnswersToCSVData = (
  ticket: GetTicketsForCsvExportQuery['listMeetingServiceRequests']['meetingServiceRequests'][number]
): { [key: string]: string } => {
  if (!ticket.completedForm?.questions) {
    return {};
  }

  const answersData = ticket.completedForm.questions.reduce((acc, question) => {
    if (!question.question.prompt) {
      return acc;
    }
    return {
      ...acc,
      [question.question.prompt]:
        question.__typename === 'AnsweredTicketQuestionExpectingText'
          ? question.answer
          : question.__typename === 'AnsweredTicketQuestionExpectingChoices'
          ? question.choices?.map((choice) => choice.name).join(', ')
          : question.__typename === 'AnsweredTicketQuestionExpectingMenuChoices'
          ? question.choices
              ?.map(
                (choice) =>
                  `${choice.quantity ? `${choice.quantity}x ` : ''}${
                    choice.option.name
                  } ${
                    choice.subtotalPrice?.currencyCode
                      ? formatCurrency(choice.subtotalPrice)
                      : ''
                  }`
              )
              .join(', ')
          : '',
    };
  }, {});

  return answersData;
};

export const mapTicketToCSVData = (
  ticket: GetTicketsForCsvExportQuery['listMeetingServiceRequests']['meetingServiceRequests'][number],
  eventTitle: string
): { [key: string]: string } => {
  // Big old object which maps the large response to all of the keys which will end up being
  // the headers for the CSV file we generate
  return {
    Key: ticket.key || '',
    Status: ticket.meetingServiceRequestStatus || '',
    'Requester User Id': ticket.requester?.id || '',
    'Requester Name': ticket.requester?.name || '',
    'Assignee User Ids Inclusive Of Group Members': [
      ...(ticket.assignees?.groups?.map(
        (group) => group?.members.map((member) => member.id) || []
      ) || []),
      ...(ticket.assignees?.usersOutsideOfGroups?.map(
        (user) => user?.id || ''
      ) || []),
    ]
      .filter((id, index, self) => self.indexOf(id) === index)
      .join(', '),
    'Assignee Names': [
      ...(ticket.assignees?.groups?.map((group) =>
        group?.members.map((member) => member.name)
      ) || []),
      ...(ticket.assignees?.usersOutsideOfGroups?.map((user) => user?.name) ||
        []),
    ]
      .filter((name, index, self) => self.indexOf(name) === index)
      .join(', '),
    'Assignee Group Ids': [
      ...(ticket.assignees?.groups?.map((group) => group?.id) || []),
    ]
      .filter((id, index, self) => self.indexOf(id) === index)
      .join(', '),
    'Assignee Group Names': [
      ...(ticket.assignees?.groups?.map((group) => group?.name) || []),
    ]
      .filter((name, index, self) => self.indexOf(name) === index)
      .join(', '),
    'Approver User Ids Inclusive Of Group Members':
      [
        ...(ticket.approvers?.userOrGroup?.__typename === 'User'
          ? [ticket.approvers?.userOrGroup?.id]
          : ticket.approvers?.userOrGroup?.__typename === 'Group'
          ? [
              ...(ticket.approvers?.userOrGroup?.members?.map(
                (member) => member?.id || ''
              ) || []),
            ]
          : []),
      ]
        .filter((id, index, self) => self.indexOf(id) === index)
        .join(', ') || '',
    'Approver Names':
      [
        ...(ticket.approvers?.userOrGroup?.__typename === 'User'
          ? [ticket.approvers?.userOrGroup?.name]
          : ticket.approvers?.userOrGroup?.__typename === 'Group'
          ? [
              ...(ticket.approvers?.userOrGroup?.members?.map(
                (member) => member?.name || ''
              ) || []),
            ]
          : []),
      ]
        .filter((name, index, self) => self.indexOf(name) === index)
        .join(', ') || '',
    'Approver Group Ids':
      [
        ...(ticket.approvers?.userOrGroup?.__typename === 'Group'
          ? [ticket.approvers?.userOrGroup?.id]
          : []),
      ]
        .filter((id, index, self) => self.indexOf(id) === index)
        .join(', ') || '',
    'Created At': ticket.createdAt || '',
    'Updated At': ticket.updatedAt || '',
    'Meeting Service Name': ticket.meetingService?.name || '',
    'Meeting Service Category': ticket.meetingService?.category?.name || '',
    'Event Id': ticket.regardsEventAtSpace?.event?.id || '',
    'Event Title': eventTitle,
    'Event Start Time': ticket.regardsEventAtSpace?.eventStart || '',
    'Event End Time': ticket.regardsEventAtSpace?.eventEnd || '',
    'Space Id': ticket.regardsEventAtSpace?.space?.id || '',
    'Space Name': ticket.regardsEventAtSpace?.space?.name || '',
    'Building Id':
      ticket.regardsEventAtSpace?.space?.locationId.toString() || '',
    'Building Name': '',
    'Floor Id': ticket.regardsEventAtSpace?.space?.levelId?.toString() || '',
    'Floor Name': '',
    'Total Price': ticket.totalPrice ? formatCurrency(ticket.totalPrice) : '',
  };
};

export type EventVisibility =
  | 'confidential'
  | 'private'
  | 'default'
  | 'personal'
  | 'public';

export const eventTitleCases = (event: {
  visibility?: string;
  title?: string;
}):
  | { type: 'sensitive' }
  | { type: 'missing' }
  | { type: 'visible'; title: string } => {
  if (event.visibility === 'confidential' || event.visibility === 'private') {
    return { type: 'sensitive' };
  }

  if (!event.title || !event.visibility) {
    return { type: 'missing' };
  }

  return { type: 'visible', title: event.title };
};

export const obfuscateTitle = (
  cases: ReturnType<typeof eventTitleCases>,
  returnText: {
    confidential: string;
    missing: string;
  }
): string => {
  switch (cases.type) {
    case 'visible':
      return cases.title;
    case 'sensitive':
      return returnText.confidential;
    default:
      return returnText.missing;
  }
};

// @TODO: this exists in the ui-kit but we need to export it
// remove this and import once it is ready
export const debounce = <ArgsType extends unknown[]>(
  callback: (...args: ArgsType) => unknown,
  delay: number
): ((...args: ArgsType) => void) & { cancel: () => void } => {
  let timer: NodeJS.Timeout;

  const debouncedFunction = (...args: ArgsType) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      callback(...args);
    }, delay);
  };

  debouncedFunction.cancel = () => {
    clearTimeout(timer);
  };

  return debouncedFunction;
};

// @TODO: This should probably live in the ui-kit as well
export const groupBy = <T, K extends string | number | symbol>(
  array: T[],
  keySelector: (item: T) => K
): Record<K, T[]> => {
  return array.reduce((acc, item) => {
    const key = keySelector(item);
    if (!acc[key]) {
      acc[key] = [];
    }
    acc[key].push(item);
    return acc;
  }, {} as Record<K, T[]>);
};

// @TODO: This should probably live in the ui-kit as well
export const uniqBy = <T, K extends string | number | symbol>(
  array: T[],
  keySelector: (item: T) => K
): T[] => {
  const seen = new Set<K>();
  return array.filter((item) => {
    const key = keySelector(item);
    if (seen.has(key)) {
      return false;
    }
    seen.add(key);
    return true;
  });
};
