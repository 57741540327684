import styled from '@emotion/styled';
import { ServiceDetailCard } from 'components/common/ServiceDetailCard/ServiceDetailCard';
import { AnsweredQuestions } from 'components/common/AnsweredQuestions/AnsweredQuestions';
import { useServiceDetailsSidebarContext } from 'components/ServiceDetailsSidebar/contexts/ServiceDetailsSidebarContext';
import moment from 'moment';
import { useMemo } from 'react';
import { Alert, Skeleton } from '@robinpowered/ui-kit';
import { useTranslation } from 'react-i18next';
import React from 'react';

export const Body = () => {
  const { t } = useTranslation('ServiceDetailsSidebar');
  const { serviceDetails, loading, locationData } =
    useServiceDetailsSidebarContext();

  const locationName = useMemo(() => {
    return [
      locationData?.getLevelById?.name,
      locationData?.getLocationById?.name,
    ]
      .filter((x) => x)
      .join(', ');
  }, [locationData]);

  const rejectionComment = serviceDetails?.rejectionComment
    ?.split('\n')
    .map((line, index) => (
      <React.Fragment key={index}>
        {line}
        <br />
      </React.Fragment>
    ));

  return (
    <Wrapper>
      {serviceDetails?.rejectionComment && (
        <Alert
          message={t('approvals_comment.title')}
          style={{
            padding: '12px',
          }}
          type="error"
          description={rejectionComment}
          showIcon
        />
      )}

      <ServiceDetailCard
        serviceId={serviceDetails?.id}
        status={
          serviceDetails
            ? {
                value: serviceDetails.meetingServiceRequestStatus,
                userCanUpdate:
                  serviceDetails.permissions?.canUpdateStatus ?? false,
              }
            : undefined
        }
        eventTitle={serviceDetails?.regardsEventAtSpace.event?.title}
        categoryName={serviceDetails?.meetingService.category.name}
        serviceName={serviceDetails?.meetingService.name}
        requestedBy={serviceDetails?.requester}
        dueDate={moment(serviceDetails?.regardsEventAtSpace.eventStart).format(
          'ddd, MMM DD, h:mm a'
        )}
        spaceName={serviceDetails?.regardsEventAtSpace.space?.name}
        locationName={locationName}
        numOfAttendees={
          serviceDetails?.regardsEventAtSpace.event?.invitees.length
        }
        serviceKey={serviceDetails?.key}
        assignees={serviceDetails?.assignees}
        approver={serviceDetails?.approvers}
        loading={loading}
      />
      {loading ? (
        <Skeleton active />
      ) : (
        <AnsweredQuestions completedForm={serviceDetails?.completedForm} />
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  padding: 1rem;
  flex: 1;
  flex-direction: column;
  gap: 1rem;
  overflow-y: auto;
`;
