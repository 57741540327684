import { useState, Dispatch, SetStateAction } from 'react';

type State<T> = {
  data: T | null;
  loading: boolean;
  error: Error | null;
};

type UseHttpState<T> = State<T> & {
  setLoading: Dispatch<SetStateAction<boolean>>;
  setData: Dispatch<SetStateAction<T | null>>;
  setError: Dispatch<SetStateAction<Error | null>>;
};

export function useHttpState<T>(): UseHttpState<T> {
  const [data, setData] = useState<T | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<Error | null>(null);

  return {
    data,
    loading,
    error,
    setLoading,
    setData,
    setError,
  };
}
