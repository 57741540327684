import { createContext, FC, useContext, useMemo } from 'react';
import {
  FeatureFlags,
  useFeatureFlags as useFeatureFlagsHook,
} from 'hooks/useFeatureFlags';
import { config } from 'config';
import { useApolloContext } from './ApolloContext';
import { Sentry } from 'lib/sentry';
import { useAccessTokenContext } from 'contexts';

interface FeatureFlagContextValue {
  checkFlag: (flag: FeatureFlags, defaultValue?: boolean) => boolean;
  loading: boolean;
  error: unknown | null;
}

const FeatureFlagContext = createContext<FeatureFlagContextValue>({
  checkFlag: () => false,
  loading: false,
  error: null,
});

export const FeatureFlagProvider: FC = ({ children }) => {
  const { tenantId } = useApolloContext();
  const { accessToken } = useAccessTokenContext();

  const {
    data: flags,
    loading,
    error,
  } = useFeatureFlagsHook(
    config.featureFlagUrl,
    accessToken ?? null,
    tenantId || null
  );

  const contextValue = useMemo(
    () => ({
      checkFlag: (flag: FeatureFlags, defaultValue = false): boolean => {
        if (loading) {
          return defaultValue;
        }
        if (
          error ||
          !flags ||
          typeof flags !== 'object' ||
          typeof flags[flag] !== 'boolean'
        ) {
          Sentry.captureException(
            `Problem checking feature flag ${flag} with tenantId ${tenantId} and accessToken 
            exists ${!!accessToken}: ${error}`
          );

          return defaultValue;
        }
        return flags[flag];
      },
      loading,
      error,
    }),
    [flags, loading, error, accessToken, tenantId]
  );

  return (
    <FeatureFlagContext.Provider value={contextValue}>
      {children}
    </FeatureFlagContext.Provider>
  );
};

export const useFeatureFlags = (): FeatureFlagContextValue => {
  const context = useContext(FeatureFlagContext);
  if (context === undefined) {
    throw new Error(
      'useFeatureFlags must be used within a FeatureFlagProvider'
    );
  }
  return context;
};
