/* eslint-disable @shopify/jsx-no-hardcoded-content */
import { ButtonV4, useFocusTrap } from '@robinpowered/design-system';
import { FullscreenModal } from 'components/ServiceForm/components/FullscreenModal';
import { useTranslation } from 'react-i18next';
import { useMemo, useState } from 'react';
import { QuestionsSection, ServiceSection } from './sections';
import { NavHeader } from '../common/NavHeader/NavHeader';
import { ServiceFormLoader } from './components/LoadingService/ServiceFormLoader';
import { useFormContext } from 'react-hook-form';
import { MeetingServiceType } from './ServiceFormContainer';
import { useMeetingServicePageContext } from 'pages/MeetingServicePage/contexts/MeetingServicePageContext';

const ServiceFormTabs = ['settings', 'questions'] as const;
type ServiceFormTabTypes = (typeof ServiceFormTabs)[number];

type Props = {
  onSubmit: () => void;
  loading: boolean;
  savingMeetingService: boolean;
  isUpdatingMeetingService: boolean;
};

export const ServiceForm = ({
  onSubmit,
  loading,
  savingMeetingService,
  isUpdatingMeetingService,
}: Props) => {
  const focus = useFocusTrap<HTMLDivElement>();
  const { t } = useTranslation('ServiceForm');
  const [currentPage, setCurrentPage] =
    useState<ServiceFormTabTypes>('settings');
  const { setServiceFormOpen } = useMeetingServicePageContext();
  const { formState } = useFormContext<MeetingServiceType>();

  const tabErrors = useMemo(() => {
    return {
      questions: !!formState.errors.questions,
      settings:
        !!formState.errors.assignees ||
        !!formState.errors.category ||
        !!formState.errors.serviceName ||
        !!formState.errors.availableInSpacesByBuilding,
    };
  }, [
    formState.errors.questions,
    formState.errors.assignees,
    formState.errors.category,
    formState.errors.serviceName,
    formState.errors.availableInSpacesByBuilding,
  ]);

  const isFormErrorsEmpty = Object.keys(formState.errors).length === 0;

  return (
    <form onSubmit={onSubmit}>
      <div ref={focus}>
        <FullscreenModal
          title={
            loading
              ? ''
              : t(`modal.title${isUpdatingMeetingService ? '_edit' : ''}`)
          }
          leftHeaderSlot={
            <ButtonV4
              variant="secondary"
              label={t('modal.cancel')}
              aria-label={t('modal.cancel')}
              onClick={() => setServiceFormOpen(false)}
            />
          }
          rightHeaderSlot={
            <ButtonV4
              type="submit"
              variant="secondary"
              label={t('modal.publish')}
              aria-label={t('modal.publish')}
              onClick={onSubmit}
              isLoading={savingMeetingService}
              disabled={!isFormErrorsEmpty || savingMeetingService}
            />
          }
        >
          <NavHeader
            fixed
            navItems={ServiceFormTabs.map((tab) => {
              return {
                label: t(`modal.${tab}`),
                tab,
                hasError: tabErrors[tab],
              };
            })}
            setTab={(page) => {
              setCurrentPage(page);
            }}
            currentTab={currentPage}
          />
          {loading ? (
            <ServiceFormLoader />
          ) : (
            <>
              {currentPage === 'settings' && <ServiceSection />}
              {currentPage === 'questions' && <QuestionsSection />}
            </>
          )}
        </FullscreenModal>
      </div>
    </form>
  );
};
