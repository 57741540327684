import { UploadProps, UploadFile } from '@robinpowered/ui-kit';
import { v4 as uuidv4 } from 'uuid';
import { useEffect, useMemo, useState } from 'react';
import { RcFile } from 'antd/es/upload';
import { useBeginFileUploadsForTicketAnswerInputMutation } from 'generated';
import { useQuestionContext } from 'components/ServiceRequest/components/QuestionTypes/contexts/QuestionContext';
import { useRequestingServiceContext } from 'components/ServiceRequest/RequestingServiceForm/contexts/RequestingServiceContext';
import { useTranslation } from 'react-i18next';
import { Sentry } from 'lib/sentry';
import { DEFAULT_TOAST_ERROR_SECONDS } from 'lib/utility';

// @TODO: Make sure you clean up this file

const MAX_FILE_SIZE_MB = 5; // 5MB
const MAX_FILE_SIZE_BYTES = MAX_FILE_SIZE_MB * 1024 * 1024; // 5MB
const MAX_FILE_COUNT = 5;

const ALLOWED_MIME_TYPES = new Set([
  // Documents
  'application/pdf',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.template',
  'application/vnd.oasis.opendocument.text',
  'application/rtf',

  // Spreadsheets
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.template',
  'application/vnd.oasis.opendocument.spreadsheet',
  'text/csv',

  // Presentations
  'application/vnd.ms-powerpoint',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  'application/vnd.openxmlformats-officedocument.presentationml.template',
  'application/vnd.openxmlformats-officedocument.presentationml.slideshow',
  'application/vnd.oasis.opendocument.presentation',

  // Images
  'image/jpeg',
  'image/png',
  'image/gif',
  'image/webp',
  'image/svg+xml',
  'image/heic',
  'image/heif',
  'image/avif',
  'image/tiff',
  'image/bmp',
  'image/jxl',
  'image/jp2',
  'image/apng',

  // Video
  'video/mp4',
  'video/mpeg',
  'video/ogg',
  'video/webm',
  'video/quicktime',
  'video/x-msvideo',
  'video/x-ms-wmv',
  'video/x-matroska',

  // Audio
  'audio/mpeg',
  'audio/mp4',
  'audio/ogg',
  'audio/wav',
  'audio/webm',
  'audio/aac',
  'audio/midi',
  'audio/x-midi',
  'audio/x-m4a',
  'audio/flac',
  'audio/x-ms-wma',

  // Text
  'text/plain',
  'text/markdown',
]);

/* File upload rules
 * Only 5 files per question
 * Only 5MB per file
 * Only ALLOWED_MIME_TYPES are allowed
 */

// Adds our uuid to the file object.  This id will only be
// set on the file object when it is successfully uploaded.
interface RobinUploadFile extends UploadFile {
  id?: string;
}

interface TicketFileUploadDestination {
  postUrl: string;
  postFields: { key: string; value: string }[];
}

export interface UseQuestionFileUploadReturn {
  draggerProps: UploadProps;
  isUploading: boolean;
  allowedMimeTypes: string;
  maxFileCount: number;
  maxFileSizeMB: number;
  disabled: boolean;
  validationError: string | undefined;
}

export const useQuestionFileUpload = (): UseQuestionFileUploadReturn => {
  const {
    id: questionId,
    setAnswers,
    answer,
    hasError,
    setHasError,
    required,
  } = useQuestionContext();

  const [fileList, setFileList] = useState<Map<string, RobinUploadFile>>(() => {
    if (answer?.type === 'file' && answer?.files.length > 0) {
      return new Map(
        answer?.files.map((f) => [
          f.name,
          {
            id: f.id,
            uid: f.id,
            name: f.name,
            status: 'done' as const,
          },
        ])
      );
    }
    return new Map();
  });
  const { toastMessage } = useRequestingServiceContext();
  const [toastError, setToastError] = useState<string | null>(null);
  const { t } = useTranslation('MeetingServiceRequest');

  // Whenever we update the fileList, also update the answers on the global question state
  useEffect(() => {
    // Only get files with ids that exist, which are uuidv4s that we set after successfully uploading
    const files = Array.from(fileList.values())
      .map((file) => {
        if (file.id) {
          return { id: file.id, name: file.name };
        }
        return null;
      })
      .filter((file) => file !== null);

    if (files.length >= 1) {
      setAnswers((prev) => {
        const prevAnswers = structuredClone(prev);

        prevAnswers.set(questionId, {
          type: 'file',
          files,
        });
        setHasError(questionId, false);
        return prevAnswers;
      });
    }
  }, [fileList, questionId, setAnswers, setHasError]);

  const validationError = useMemo(
    () => (hasError && required ? t('errors.required') : undefined),
    [hasError, required, t]
  );

  const handleFileUploadError = (file: UploadFile, errorMessage: string) => {
    setFileListError(file, errorMessage);
    setToastError(errorMessage);
  };

  const setFileListError = (file: UploadFile, errorMessage: string) => {
    setFileList((prev) => {
      const newFileList = new Map(prev);
      if (fileList.has(file.name)) {
        return prev;
      }
      newFileList.set(file.name, {
        ...file,
        name: file.name,
        status: 'error',
        response: errorMessage,
      });
      return newFileList;
    });
  };

  const setFileListSuccess = (file: RobinUploadFile, fileId: string) => {
    setFileList((prev) => {
      const newFileList = new Map(prev);
      newFileList.set(file.name, {
        ...file,
        id: fileId,
        name: file.name,
        status: 'done',
      });
      return newFileList;
    });
  };

  const [beginFileUploadsForTicketAnswer] =
    useBeginFileUploadsForTicketAnswerInputMutation();

  const validateBasicFileProperties = (file: UploadFile): boolean => {
    if (fileList.has(file.name)) {
      setToastError(
        t('file_upload.errors.alreadyUploaded', {
          fileName: file.name,
        })
      );
      return false;
    }

    if (file.type && !ALLOWED_MIME_TYPES.has(file.type)) {
      const errorMessage = t('file_upload.errors.invalidFileType', {
        fileName: file.name,
      });
      handleFileUploadError(file, errorMessage);
      return false;
    }

    if (file.size === 0) {
      const errorMessage = t('file_upload.errors.emptyFile', {
        fileName: file.name,
      });
      handleFileUploadError(file, errorMessage);
      return false;
    }

    if (file.size && file.size > MAX_FILE_SIZE_BYTES) {
      const errorMessage = t('file_upload.errors.fileTooLarge', {
        fileName: file.name,
        maxSizeMb: MAX_FILE_SIZE_MB,
      });
      handleFileUploadError(file, errorMessage);
      return false;
    }

    return true;
  };

  /**
   * Validates if adding these files would exceed the maximum allowed number of files
   */
  const validateTooManyFiles = (
    beforeUploadFileList: UploadFile[],
    file: UploadFile
  ): boolean => {
    // Get count of already successfully uploaded files
    const doneFiles = Array.from(fileList.values()).filter(
      (file) => file.status === 'done'
    );
    const availableSpace = MAX_FILE_COUNT - doneFiles.length;

    // If total files being uploaded fits within available space, allow it
    if (beforeUploadFileList.length <= availableSpace) {
      return true;
    }

    // Find position of current file in upload list
    const index = beforeUploadFileList.findIndex((f) => f.name === file.name);

    // Count how many valid files come before this one
    const validFilesBefore = beforeUploadFileList
      .slice(0, index)
      .filter((f) => validateBasicFileProperties(f)).length;

    // If too many valid files come before this one, reject it
    if (validFilesBefore >= availableSpace) {
      const errorMessage = t('file_upload.errors.maxFiles', {
        maxFiles: MAX_FILE_COUNT,
      });
      handleFileUploadError(file, errorMessage);
      return false;
    }

    return true;
  };

  const validateFile = (
    file: RcFile,
    beforeUploadFileList: UploadFile[]
  ): boolean => {
    if (!validateBasicFileProperties(file)) return false;
    if (!validateTooManyFiles(beforeUploadFileList, file)) return false;
    return true;
  };

  const uploadFileToS3 = async (
    file: RcFile,
    uploadDestination: TicketFileUploadDestination
  ): Promise<boolean> => {
    try {
      const formData = new FormData();

      uploadDestination.postFields.forEach(({ key, value }) => {
        formData.append(key, value);
      });

      formData.append('file', file);

      const response = await fetch(uploadDestination.postUrl, {
        method: 'POST',
        body: formData,
      });

      if (!response.ok) {
        toastMessage(
          'error',
          t('file_upload.errors.basic'),
          DEFAULT_TOAST_ERROR_SECONDS
        );
        throw new Error(`Upload failed: ${response.body}`);
      }

      return true;
    } catch (error) {
      Sentry.captureException(error);
      return false;
    }
  };

  const customUpload = async ({ file }: { file: RcFile }) => {
    const fileId = uuidv4();

    try {
      const uploadDetailsResponse = await beginFileUploadsForTicketAnswer({
        variables: {
          input: {
            files: [
              {
                id: fileId,
                name: file.name,
                mimeType: file.type,
                sizeBytes: file.size,
              },
            ],
            questionId,
          },
        },
      });

      const uploadData =
        uploadDetailsResponse.data?.beginFileUploadsForTicketAnswer;

      if (
        uploadData?.__typename !==
        'BeginFileUploadsForTicketAnswerSuccessResponse'
      ) {
        toastMessage(
          'error',
          t('file_upload.errors.basic'),
          DEFAULT_TOAST_ERROR_SECONDS
        );
        throw new Error('Failed to get upload details from server.');
      }

      const uploadDestination = uploadData.destinations[0];

      const success = await uploadFileToS3(file, uploadDestination);

      if (success) {
        toastMessage(
          'success',
          t('file_upload.success', {
            fileName: file.name,
          })
        );

        setFileListSuccess(file, fileId);
      } else {
        throw new Error(`Failed to upload file ${file.name}`);
      }
    } catch (error) {
      Sentry.captureException(error);
      const errorMessage = t('file_upload.errors.failedToUpload', {
        fileName: file.name,
      });
      handleFileUploadError(file, errorMessage);
    }
  };

  useEffect(() => {
    if (toastError) {
      toastMessage('error', toastError, DEFAULT_TOAST_ERROR_SECONDS);
      setToastError(null);
    }
  }, [toastError, toastMessage, setToastError]);

  const draggerProps: UploadProps<RobinUploadFile> = {
    multiple: true,
    maxCount: MAX_FILE_COUNT,
    fileList: Array.from(fileList.values()),
    accept: Array.from(ALLOWED_MIME_TYPES).join(','),
    onRemove: (file) => {
      setFileList((prev) => {
        const newFileList = new Map(prev);
        newFileList.delete(file.name);
        return newFileList;
      });

      setAnswers((prev) => {
        const prevAnswers = structuredClone(prev);
        prevAnswers.delete(questionId);
        return prevAnswers;
      });
    },
    beforeUpload: (file, beforeUploadFileList) => {
      if (!validateFile(file, beforeUploadFileList)) {
        return false;
      }
      setFileList((prev) => {
        const newFileList = new Map(prev);
        if (newFileList.has(file.name)) {
          return prev;
        }
        newFileList.set(file.name, {
          ...file,
          name: file.name,
          status: 'uploading',
        });
        return newFileList;
      });
      customUpload({ file });
      return false;
    },
  };

  const isUploading = Array.from(fileList.values()).some(
    (f) => f.status === 'uploading'
  );

  return {
    draggerProps,
    isUploading,
    allowedMimeTypes: Array.from(ALLOWED_MIME_TYPES).join(','),
    maxFileCount: MAX_FILE_COUNT,
    maxFileSizeMB: MAX_FILE_SIZE_MB,
    disabled: isUploading,
    validationError,
  };
};
