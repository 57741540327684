import { useCallback, useState } from 'react';
import { Answers } from '../types/ServiceRequestTypes';

type Question = {
  id: string;
  required: boolean;
};

export const useValidateData = (questions: Question[]) => {
  const [errors, setErrors] = useState<Map<string, boolean>>(
    () => new Map(questions.map((q) => [q.id, false]))
  );

  const areAnswersValid = useCallback(
    (answers: Answers) => {
      const requiredQuestions = questions.filter((q) => q.required);
      let hasError = false;
      for (const question of requiredQuestions) {
        const answerToQuestion = answers.get(question.id);

        if (
          !answers.has(question.id) ||
          (answerToQuestion?.type === 'choices' &&
            answerToQuestion.options.size === 0) ||
          (answerToQuestion?.type === 'text' && !answerToQuestion.text)
        ) {
          setErrors((prev) => {
            const newMap = new Map(prev);
            newMap.set(question.id, true);
            return newMap;
          });
          hasError = true;
        }
      }

      return !hasError;
    },
    [questions, setErrors]
  );

  const handleAnswerError = useCallback(
    (questionId: string, hasError: boolean) => {
      setErrors((prev) => {
        const newMap = new Map(prev);
        hasError ? newMap.set(questionId, hasError) : newMap.delete(questionId);
        return newMap;
      });
    },
    [setErrors]
  );

  return {
    setErrors,
    errors,
    areAnswersValid,
    handleAnswerError,
  };
};
