import React, { useEffect, useState, useRef } from 'react';
import styled from '@emotion/styled';
import { ButtonV4, Colors } from '@robinpowered/design-system';
import { AnimatedSlider } from './AnimatedSlider';
import WarningSolid from '@robinpowered/icons/WarningSolid';
import theme from '@robinpowered/design-system/dist/theme';

type Props<T extends string> = {
  navItems: { label: string; tab: T; hasError?: boolean }[];
  setTab: (type: T) => void;
  currentTab: T;
  fixed?: boolean;
};

export const NavHeader = <T extends string>({
  navItems,
  setTab,
  currentTab,
  fixed = false,
}: Props<T>) => {
  const navHeaderRef = useRef<HTMLDivElement>(null);
  const itemsRef = useRef<(HTMLDivElement | null)[]>([]);

  const [navItemsWidths, setNavItemsWidths] = useState<number[]>([]);

  useEffect(() => {
    setNavItemsWidths(itemsRef.current.map((ref) => ref?.offsetWidth || 0));
  }, []);

  return (
    <Header fixed={fixed}>
      <div
        ref={navHeaderRef}
        style={{
          display: 'flex',
          flexDirection: 'row',
          position: 'relative',
        }}
      >
        <AnimatedSlider
          selectedElementIndex={navItems.findIndex((a) => a.tab === currentTab)}
          style={{
            borderBottom: `3px solid ${Colors.Maroon100}`,
          }}
          navItemsWidths={navItemsWidths}
        />

        {navItems.map((item, index) => {
          return (
            <div
              key={index}
              ref={(el) => (itemsRef.current[index] = el)}
              style={{ position: 'relative' }}
            >
              {item.hasError && (
                <div
                  style={{ position: 'absolute', left: 0, zIndex: 99 }}
                  data-testid="error-icon"
                >
                  <WarningSolid
                    size={16}
                    color={theme.colorTokens.utilities.negative}
                  />
                </div>
              )}

              <NavButton
                variant="tertiary"
                label={item.label}
                aria-label={item.label}
                onClick={() => setTab(item.tab)}
              />
            </div>
          );
        })}
      </div>
    </Header>
  );
};

const Header = styled.header<{ fixed?: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: ${Colors.White0};
  ${({ fixed }) => {
    return fixed
      ? `
  position: fixed;
  width: 100%;
  z-index: 99999; 
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  `
      : ``;
  }}
`;

const NavButton = styled(ButtonV4)`
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
`;
