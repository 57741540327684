import styled from '@emotion/styled';
import { Colors, SpinnerLoader, Flex } from '@robinpowered/design-system';
import {
  RequestingServiceContextProvider,
  useRequestingServiceContext,
} from './contexts/RequestingServiceContext';
import { RobinLoader } from 'components/RobinLoader';
import { Alert } from '@robinpowered/ui-kit';
import { useTranslation } from 'react-i18next';
import { ServiceDetailCard } from 'components/common/ServiceDetailCard/ServiceDetailCard';
import { MarkdownRenderer } from 'components/common/MarkdownRenderer/MarkdownRenderer';
import { SelectCategory } from './components/SelectCategory';
import { SelectService } from './components/SelectService';
import { ServiceQuestions } from '../components/ServiceQuestions';
import { WorkplaceServiceFormRequest } from '@robinpowered/common-lib';

const RequestingServiceContainer = () => {
  const {
    loading,
    isRecurring,
    requestedBy,
    dueDate,
    locationName,
    numOfAttendees,
    loadingLocation,
    selectedServiceWithoutQuestions,
    assignees,
    approver,
    gettingServiceWithQuestions,
    serviceDescription,
    questions,
    serviceRequestAnswers,
    setServiceRequestAnswers,
    errors,
    handleAnswerError,
    toastContextHolder,
  } = useRequestingServiceContext();
  const { t } = useTranslation('MeetingServiceRequest');

  if (loading) {
    return <RobinLoader />;
  }

  return (
    <Wrapper>
      {toastContextHolder}
      {isRecurring && (
        <Alert
          message={t(`recurrence_alert.add_service`)}
          type="info"
          showIcon
        />
      )}
      <ServiceDetailCard
        dueDate={dueDate}
        loading={loading || loadingLocation}
        requestedBy={requestedBy}
        hideEventTitle
        locationName={locationName}
        numOfAttendees={numOfAttendees}
        serviceName={selectedServiceWithoutQuestions?.name}
        categoryName={selectedServiceWithoutQuestions?.category.name}
        assignees={assignees}
        approver={approver}
      />
      <SelectCategory />
      <SelectService />

      {gettingServiceWithQuestions ? (
        <Flex
          style={{
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex',
            marginTop: '16px',
          }}
        >
          <SpinnerLoader />
        </Flex>
      ) : (
        <>
          {serviceDescription && (
            <MarkdownRenderer markdownString={serviceDescription} />
          )}
          {questions && (
            <ServiceQuestions
              serviceQuestions={questions}
              answers={serviceRequestAnswers}
              setAnswers={setServiceRequestAnswers}
              setErrors={handleAnswerError}
              errors={errors}
            />
          )}
        </>
      )}
    </Wrapper>
  );
};

// Test initial values are meant to only be used in jest/react-testing-library tests
export const RequestingServiceForm = ({
  __testInitialValues__,
}: {
  __testInitialValues__?: WorkplaceServiceFormRequest;
}) => {
  return (
    <RequestingServiceContextProvider
      __testInitialValues__={__testInitialValues__}
    >
      <RequestingServiceContainer />
    </RequestingServiceContextProvider>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  height: 100%;
  background-color: ${Colors.White0};
  padding: 16px;

  overflow-y: auto;
`;
