import { Button, Skeleton, Typography, theme } from '@robinpowered/ui-kit';
import styled from '@emotion/styled';
import { useServiceDetailsSidebarContext } from '../contexts/ServiceDetailsSidebarContext';
import SvgClose from '@robinpowered/ui-kit-icons/Close';
import { useTranslation } from 'react-i18next';
import { DownloadTicketPdf } from 'components/ServiceDetailsSidebar/PDFDownload/DownloadTicketPdf';

export const Header = () => {
  const { t } = useTranslation('ServiceDetailsSidebar');
  const { serviceDetails, loading } = useServiceDetailsSidebarContext();
  const { useToken } = theme;
  const { token } = useToken();
  const { setSearchParams } = useServiceDetailsSidebarContext();

  return (
    <Wrapper style={{ borderBottom: `1px solid ${token.colorBorder}` }}>
      {loading || !serviceDetails ? (
        <Skeleton active paragraph={false} />
      ) : (
        <Typography.Title level={4} ellipsis={{ rows: 2 }}>
          {serviceDetails?.regardsEventAtSpace.event?.title
            ? t(`title`, {
                serviceName: serviceDetails?.meetingService.name,
                eventTitle: serviceDetails?.regardsEventAtSpace.event?.title,
                key: serviceDetails?.key,
              })
            : t(`title_no_event`, {
                serviceName: serviceDetails?.meetingService.name,
                key: serviceDetails?.key,
              })}
        </Typography.Title>
      )}

      <IconContainer>
        <DownloadTicketPdf />
        <Button
          type="text"
          shape="default"
          size="middle"
          icon={<SvgClose />}
          onClick={() => {
            setSearchParams(new URLSearchParams());
          }}
        />
      </IconContainer>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  padding: 1rem;
  align-items: center;
  justify-content: space-between;
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  margin-left: 1rem;
`;
