import { createContext, useContext, FC, ReactNode, useEffect } from 'react';
import {
  GetLocationNameDetailsForServiceRequestDetailsSidebarQuery,
  GetMeetingServicesForAdminTableListQuery,
  GetServiceRequestByIdForDetailSidebarQuery,
  MeetingServiceRequestPermissions,
  useGetLocationNameDetailsForServiceRequestDetailsSidebarQuery,
  useGetServiceRequestByIdForDetailSidebarQuery,
} from 'generated';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useApolloContext, useAuthContext } from 'contexts';
import { useTranslation } from 'react-i18next';
import { TicketsRoutes } from 'App';
import { Sentry } from 'lib/sentry';
import { useTicketsListPageContext } from 'pages/TicketsListPage/contexts/TicketsListPageContext';
import { useFileDownload } from '../hooks/useFileDownload';

export type MeetingServiceType =
  GetMeetingServicesForAdminTableListQuery['listMeetingServices']['meetingServices'][0];

type ServiceDetailsSidebarValue = {
  serviceDetails:
    | GetServiceRequestByIdForDetailSidebarQuery['getMeetingServiceRequestByIdentifier']['meetingServiceRequest']
    | undefined;
  loading: boolean;
  locationData:
    | GetLocationNameDetailsForServiceRequestDetailsSidebarQuery
    | undefined;
  serviceRequestKey: string | null;
  setSearchParams: (params: URLSearchParams) => void;
  ticketPermissions: MeetingServiceRequestPermissions | undefined | null;
  refetchServiceDetails: () => void;
};

const ServiceDetailsSidebarContext = createContext<ServiceDetailsSidebarValue>({
  serviceDetails: undefined,
  loading: false,
  locationData: undefined,
  serviceRequestKey: null,
  setSearchParams: () => null,
  ticketPermissions: undefined,
  refetchServiceDetails: () => null,
});

export const ServiceDetailsSidebarContextProvider: FC<{
  children: ReactNode;
}> = ({ children }) => {
  const { tenantId } = useApolloContext();
  const { currentOrg } = useAuthContext();
  const { t } = useTranslation('ServiceDetailsSidebar');

  const { toastMessage } = useTicketsListPageContext();

  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const serviceRequestKey = searchParams.get('serviceRequestKey');
  const fileId = searchParams.get('fileId');
  const { downloadFile, downloadingFile } = useFileDownload();

  /* If we have a fileId, we need to download the file first*/
  useEffect(() => {
    if (fileId && serviceRequestKey) {
      downloadFile({
        fileId,
        ticketIdentifier: {
          key: serviceRequestKey,
        },
        fileName: null,
      });
      setSearchParams(
        (prev) => {
          const newParams = new URLSearchParams(prev);
          newParams.delete('fileId');
          return newParams;
        },
        { replace: true }
      );
    }
  }, [fileId, serviceRequestKey, downloadFile, setSearchParams]);

  const navigateWithRemovedParam = (paramName: string) => {
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.delete(paramName);
    // Using the replace prevents the users from hitting a looo
    // using the browser back btn to hit a page that keeps erroring
    navigate(
      `/${currentOrg?.slug}/tickets/${
        TicketsRoutes.TICKETS_LIST
      }?${newSearchParams.toString()}`,
      {
        replace: true,
      }
    );
  };

  const {
    data,
    loading,
    refetch: refetchServiceDetails,
  } = useGetServiceRequestByIdForDetailSidebarQuery({
    variables: {
      identifier: {
        key: serviceRequestKey,
      },
    },
    skip: !serviceRequestKey || !tenantId,
    /* @TODO: Rethink how we handle data/errors here. */
    errorPolicy: 'all',
    onError: (error) => {
      Sentry.captureException(error);
      if (error.message && data?.getMeetingServiceRequestByIdentifier) {
        return;
      } else {
        toastMessage('error', t('errors.loading_service_details'));
        navigateWithRemovedParam('serviceRequestKey');
      }
    },
    onCompleted: (data) => {
      // If the service details are not found, clear the search params
      if (!data.getMeetingServiceRequestByIdentifier.meetingServiceRequest) {
        toastMessage('error', t('errors.not_found'));
        navigateWithRemovedParam('serviceRequestKey');
      }
    },
  });

  const serviceDetails =
    data?.getMeetingServiceRequestByIdentifier.meetingServiceRequest;

  const { data: locationData, loading: locationLoading } =
    useGetLocationNameDetailsForServiceRequestDetailsSidebarQuery({
      variables: {
        locationId:
          serviceDetails?.regardsEventAtSpace.space?.locationId.toString() ||
          '',
        levelId: serviceDetails?.regardsEventAtSpace.space?.levelId || '',
        hasLevelId: !!serviceDetails?.regardsEventAtSpace.space?.levelId,
      },
      skip: !serviceDetails?.regardsEventAtSpace.space?.locationId,
    });

  return (
    <ServiceDetailsSidebarContext.Provider
      value={{
        serviceRequestKey,
        setSearchParams,
        serviceDetails,
        locationData,
        loading: loading || locationLoading || downloadingFile,
        ticketPermissions: serviceDetails?.permissions,
        refetchServiceDetails,
      }}
    >
      {children}
    </ServiceDetailsSidebarContext.Provider>
  );
};

export const useServiceDetailsSidebarContext =
  (): ServiceDetailsSidebarValue => {
    return useContext(ServiceDetailsSidebarContext);
  };
