import { createContext, FC, useContext } from 'react';
import { useAccessToken } from './useAccessToken';

type AccessTokenContextValue = {
  accessToken: string | null | undefined;
  setExternalAccessToken: (token: string | null | undefined) => void;
  isExternalTokenReady: boolean;
  shouldUseParentToken: boolean;
};

const AccessTokenContext = createContext<AccessTokenContextValue>({
  accessToken: undefined,
  setExternalAccessToken: () => null,
  isExternalTokenReady: false,
  shouldUseParentToken: false,
});

export const AccessTokenProvider: FC = ({ children }) => {
  const {
    accessToken,
    setExternalAccessToken,
    isExternalTokenReady,
    shouldUseParentToken,
  } = useAccessToken();

  return (
    <AccessTokenContext.Provider
      value={{
        accessToken,
        setExternalAccessToken,
        isExternalTokenReady,
        shouldUseParentToken,
      }}
    >
      {children}
    </AccessTokenContext.Provider>
  );
};

export const useAccessTokenContext = (): AccessTokenContextValue => {
  return useContext(AccessTokenContext);
};
