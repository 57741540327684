import { useAuthContext } from 'contexts';
import {
  ApproveMeetingServiceRequestInput,
  GetTicketsForTicketsListPageQuery,
  GetTicketsForTicketsListPageQueryVariables,
  ListMeetingServiceRequestsFiltersInput,
  ListMeetingServiceRequestsSortByInput,
  ListUniqueMeetingServiceRequestValuesQuery,
  RejectMeetingServiceRequestInput,
  useGetTicketsForTicketsListPageQuery,
} from 'generated';
import {
  createContext,
  useContext,
  FC,
  ReactNode,
  SetStateAction,
  Dispatch,
  useState,
  JSXElementConstructor,
  ReactElement,
  useCallback,
  useEffect,
} from 'react';
import { message } from '@robinpowered/ui-kit';
import { NoticeType } from 'antd/es/message/interface';
import { useApprovals } from '../hooks/useApprovals';
import { useManageMeetingServicesLocations } from 'hooks/useManageMeetingServicesLocations';
import moment from 'moment-timezone';
import { useManageTicketListFiltersAndSort } from 'components/TicketsListTable/hooks/useManageTicketListFiltersAndSort';
import type { FilterValue, SorterResult } from 'antd/lib/table/interface';
import { TicketsListTableDataType } from 'components/TicketsListTable/hooks/useManageTicketsListTable';
import { useTenantLocalStorage } from 'hooks/useTenantLocalStorage';
import { Sentry } from 'lib/sentry';

const SELECTED_LOCATIONS_TICKETS_LIST = 'selected-locations-tickets-list';
type RefetchFilters = {
  first?: number;
  skip?: number | undefined;
  after?: string | undefined;
  sortBy?: ListMeetingServiceRequestsSortByInput | undefined;
  selectedLocationIds?: string[];
};
type TicketsListPageValue = {
  data: GetTicketsForTicketsListPageQuery | undefined;
  ticketById: Map<
    string,
    GetTicketsForTicketsListPageQuery['listMeetingServiceRequests']['meetingServiceRequests'][0]
  >;
  refetchTickets: (filters?: RefetchFilters) => void;
  loading: boolean;
  meetingServiceRequestsCount: number;
  sortByForListServiceRequestsQuery:
    | ListMeetingServiceRequestsSortByInput
    | null
    | undefined;
  handleSetSortByForListServiceRequestsQuery: (
    sorter:
      | SorterResult<TicketsListTableDataType>
      | SorterResult<TicketsListTableDataType>[]
  ) => void;
  getUniqueMeetingServiceRequestValues: () => void;
  uniqueMeetingServiceRequestValues:
    | ListUniqueMeetingServiceRequestValuesQuery['listUniqueMeetingServiceRequestValues']['uniqueValues']
    | undefined;
  toastContextHolder: ReactElement<
    unknown,
    string | JSXElementConstructor<unknown>
  >;
  toastMessage: (
    type: NoticeType,
    message: string,
    durationInSeconds?: number
  ) => void;
  handleApproveMeetingServiceRequest: (
    input: ApproveMeetingServiceRequestInput
  ) => void;
  handleRejectMeetingServiceRequest: (
    input: RejectMeetingServiceRequestInput
  ) => void;
  selectedTicketIdForApprovalProcess: {
    id: string;
    status: 'approving' | 'rejecting';
  } | null;
  setSelectedTicketIdForApprovalProcess: Dispatch<
    SetStateAction<{
      id: string;
      status: 'approving' | 'rejecting';
    } | null>
  >;
  approvalProcessing: boolean;
  selectedLocationIds: string[];
  fetchTicketsForTicketsListVariables: (
    extraFilters?: RefetchFilters
  ) => GetTicketsForTicketsListPageQueryVariables;
  setSelectedLocationIds: (value: string[]) => void;
  dateRange: { startDate: string; endDate: string };
  setDateRange: Dispatch<
    SetStateAction<
      | {
          startDate: string;
          endDate: string;
        }
      | undefined
    >
  >;
  resetDateRange: () => void;
  filtersForMeetingServiceRequestsQuery:
    | ListMeetingServiceRequestsFiltersInput
    | undefined;
  handleSetFiltersForMeetingServiceRequestsQuery: (
    filters: Record<string, FilterValue | null>
  ) => void;
  setFiltersForMeetingServiceRequestsQuery: Dispatch<
    SetStateAction<ListMeetingServiceRequestsFiltersInput | undefined>
  >;
  handleClearAllFilters: () => void;
  filtersAreApplied: boolean;
  loadingLocations: boolean;
  organizationLocations: {
    id: string;
    name: string;
  }[];
};

const TicketsListPageContext = createContext<TicketsListPageValue>({
  data: undefined,
  ticketById: new Map(),
  refetchTickets: () => null,
  loading: true,
  meetingServiceRequestsCount: 0,
  handleSetSortByForListServiceRequestsQuery: () => null,
  sortByForListServiceRequestsQuery: null,
  getUniqueMeetingServiceRequestValues: () => null,
  uniqueMeetingServiceRequestValues: undefined,
  toastContextHolder: <></>,
  toastMessage: () => null,
  handleApproveMeetingServiceRequest: () => null,
  handleRejectMeetingServiceRequest: () => null,
  /* @TODO this should be selectedTickets? In the future we will have multiple tickets selected that can be deleted */
  selectedTicketIdForApprovalProcess: null,
  setSelectedTicketIdForApprovalProcess: () => null,
  approvalProcessing: false,
  selectedLocationIds: [],
  setSelectedLocationIds: () => null,
  dateRange: { startDate: '', endDate: '' },
  setDateRange: () => null,
  resetDateRange: () => null,
  filtersForMeetingServiceRequestsQuery: undefined,
  handleSetFiltersForMeetingServiceRequestsQuery: () => null,
  setFiltersForMeetingServiceRequestsQuery: () => null,
  handleClearAllFilters: () => null,
  filtersAreApplied: false,
  loadingLocations: false,
  organizationLocations: [],
  fetchTicketsForTicketsListVariables: () => ({
    input: {
      first: TICKETS_PER_TABLE_PAGE,
      skip: undefined,
      after: undefined,
    },
  }),
});

type Props = {
  children: ReactNode;
};

export const TICKETS_PER_TABLE_PAGE = 15;

export const TicketsListPageContextProvider: FC<Props> = ({ children }) => {
  // Product requirement is the default range is today plus 7 days
  const defaultDateRange = {
    startDate: moment.tz(moment.tz.guess()).startOf('day').toISOString(),
    endDate: moment
      .tz(moment.tz.guess())
      .add(7, 'day')
      .endOf('day')
      .toISOString(),
  };
  const { loading: loadingAuth } = useAuthContext();

  // Kind of hacky, but handles the loading state not properly updating when refetching
  // only use it in this file
  const [isRefetching, setIsRefetching] = useState(false);

  const [messageApi, contextHolder] = message.useMessage();
  const toastMessage = useCallback(
    (type: NoticeType, message: string, durationInSeconds?: number) => {
      messageApi.open({
        type,
        content: message,
        duration: durationInSeconds || 3,
      });
    },
    [messageApi]
  );

  const {
    locationsUserCanManage,
    loading: loadingLocations,
    organizationLocations,
  } = useManageMeetingServicesLocations();

  const [selectedLocationIds = [], setSelectedLocationIds] =
    useTenantLocalStorage<string[]>(SELECTED_LOCATIONS_TICKETS_LIST, []);

  useEffect(() => {
    // If the local storage is empty, then we want to set the default
    // selected locations to the locations the user can manage
    if (
      !loadingLocations &&
      locationsUserCanManage?.length > 0 &&
      selectedLocationIds.length === 0
    ) {
      setSelectedLocationIds(
        locationsUserCanManage.map((location) => location?.id || '')
      );
    }
    // We rely on the initial selectedLocationIds length to be 0 on first render
    // so that we can set a default value for the selected locations
    // we do not want this to run every time selectedLocationIds changes so we omit in the dependency array
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationsUserCanManage, setSelectedLocationIds, loadingLocations]);

  const {
    dataForCountServiceRequests,
    refetchMeetingServiceRequestsCount,
    dateRange,
    setDateRange,
    filtersForMeetingServiceRequestsQuery,
    handleSetFiltersForMeetingServiceRequestsQuery,
    sortByForListServiceRequestsQuery,
    handleSetSortByForListServiceRequestsQuery,
    getUniqueMeetingServiceRequestValues,
    uniqueMeetingServiceRequestValues,
    setFiltersForMeetingServiceRequestsQuery,
    handleClearAllFilters,
    filtersAreApplied,
  } = useManageTicketListFiltersAndSort(
    defaultDateRange,
    selectedLocationIds,
    loadingLocations
  );

  const fetchTicketsForTicketsListVariables = useCallback(
    (extraFilters?: RefetchFilters) => {
      const filters = {
        eventStartTimeBetween: {
          start: dateRange.startDate,
          end: dateRange.endDate,
        },
        regardingAnyBuildingIdsOrTheirDescendants:
          extraFilters?.selectedLocationIds || selectedLocationIds,
        ...filtersForMeetingServiceRequestsQuery,
      };
      return {
        input: {
          first: extraFilters?.first || TICKETS_PER_TABLE_PAGE,
          filters,
          sortBy:
            extraFilters?.sortBy ||
            sortByForListServiceRequestsQuery ||
            undefined,
          skip: extraFilters?.skip,
          after: extraFilters?.after,
        },
      };
    },
    [
      dateRange,
      filtersForMeetingServiceRequestsQuery,
      sortByForListServiceRequestsQuery,
      selectedLocationIds,
    ]
  );

  const { data, loading, refetch } = useGetTicketsForTicketsListPageQuery({
    variables: {
      input: {
        first: TICKETS_PER_TABLE_PAGE,
        sortBy: sortByForListServiceRequestsQuery || undefined,
        filters: {
          eventStartTimeBetween: {
            start: dateRange.startDate,
            end: dateRange.endDate,
          },
          regardingAnyBuildingIdsOrTheirDescendants: selectedLocationIds,
          ...filtersForMeetingServiceRequestsQuery,
        },
        skip: undefined,
        after: undefined,
      },
    },
    skip: loadingAuth,
    /* @TODO: Rethink how we handle data/errors here. */
    errorPolicy: 'all',
    onError: (error) => {
      Sentry.captureException(error);
    },
  });

  const [ticketById, setTicketById] = useState<
    Map<
      string,
      GetTicketsForTicketsListPageQuery['listMeetingServiceRequests']['meetingServiceRequests'][0]
    >
  >(new Map());

  const refetchTickets = useCallback(
    async (refetchFilters?: RefetchFilters) => {
      setIsRefetching(true);
      const refetchVariables =
        fetchTicketsForTicketsListVariables(refetchFilters);
      await refetch(refetchVariables);

      // Always refetch the meeting services count for pagination whenever we refetch the tickets to
      // keep them in sync
      const { input } = refetchVariables;
      const { filters } = input;
      await refetchMeetingServiceRequestsCount({
        input: {
          filters: {
            ...filters,
          },
        },
      });
      setIsRefetching(false);
    },
    [
      fetchTicketsForTicketsListVariables,
      refetch,
      refetchMeetingServiceRequestsCount,
    ]
  );

  useEffect(() => {
    // We turned ticketById into a useState so we could keep a reference to the previous value and
    // prevent the table from 'flickering' with no data when a refetch is called by the filters or sorts
    if (!loading) {
      const ticketData =
        data?.listMeetingServiceRequests.meetingServiceRequests;
      setTicketById(new Map(ticketData?.map((ticket) => [ticket.id, ticket])));
    }
  }, [data, loading]);

  const {
    handleApproveMeetingServiceRequest,
    handleRejectMeetingServiceRequest,
    selectedTicketIdForApprovalProcess,
    setSelectedTicketIdForApprovalProcess,
    approvalProcessing,
  } = useApprovals({
    toastMessage,
  });

  return (
    <TicketsListPageContext.Provider
      value={{
        meetingServiceRequestsCount:
          dataForCountServiceRequests?.countMeetingServiceRequests.count || 0,
        data,
        ticketById,
        loading: loading || isRefetching,
        loadingLocations,
        handleSetSortByForListServiceRequestsQuery,
        sortByForListServiceRequestsQuery,
        getUniqueMeetingServiceRequestValues,
        organizationLocations,
        uniqueMeetingServiceRequestValues:
          uniqueMeetingServiceRequestValues
            ?.listUniqueMeetingServiceRequestValues.uniqueValues,
        refetchTickets,
        fetchTicketsForTicketsListVariables,
        toastContextHolder: contextHolder,
        toastMessage,
        handleApproveMeetingServiceRequest,
        handleRejectMeetingServiceRequest,
        selectedTicketIdForApprovalProcess,
        setSelectedTicketIdForApprovalProcess,
        approvalProcessing,
        selectedLocationIds,
        setSelectedLocationIds,
        dateRange,
        setDateRange,
        filtersForMeetingServiceRequestsQuery,
        handleSetFiltersForMeetingServiceRequestsQuery,
        setFiltersForMeetingServiceRequestsQuery,
        handleClearAllFilters,
        resetDateRange: () => {
          setDateRange(defaultDateRange);
        },
        filtersAreApplied,
      }}
    >
      {children}
    </TicketsListPageContext.Provider>
  );
};

export const useTicketsListPageContext = (): TicketsListPageValue => {
  return useContext(TicketsListPageContext);
};
