import { useState } from 'react';

export const ServiceFormTabs = ['meetingService', 'categories'] as const;
export type ServiceFormTabTypes = (typeof ServiceFormTabs)[number];

type UseToggleServiceFormReturn = {
  serviceId: string;
  serviceFormOpen: boolean;
  setServiceFormOpen: (open: boolean) => void;
  openServiceFormWithId: (serviceId: string) => void;
};

export const useToggleServiceForm = (): UseToggleServiceFormReturn => {
  const [serviceFormOpen, setServiceFormOpen] = useState(false);
  const [serviceId, setServiceId] = useState('');

  const openServiceFormWithId = (serviceId: string) => {
    setServiceId(serviceId);
    setServiceFormOpen(true);
  };

  return {
    serviceId,
    serviceFormOpen,
    setServiceFormOpen,
    openServiceFormWithId,
  };
};
