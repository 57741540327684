import { useTranslation } from 'react-i18next';
import { MultiSelect } from 'components/ServiceForm/components/MultiSelect';
import BuildingSolid from '@robinpowered/icons/BuildingSolid';
import { useManageSelectBuildingInput } from './hooks/useManageSelectBuildingInput';

export const SelectBuildingsInput = () => {
  const { t } = useTranslation('ServiceSection');
  const { options, value, handleSelectBuilding, selectAllOption, inputText } =
    useManageSelectBuildingInput();
  return (
    <MultiSelect
      value={value}
      name={t(`location.select_location.name`)}
      onSelectOption={handleSelectBuilding}
      options={options}
      selectAllOption={selectAllOption}
      inputText={inputText}
      placeholder={t(`location.select_location.placeholder`)}
      icon={BuildingSolid}
      style={{ width: '100%' }}
    />
  );
};
