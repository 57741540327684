import { useEffect } from 'react';
import {
  BrowserRouter,
  Routes,
  Route,
  useParams,
  Outlet,
} from 'react-router-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { I18nextProvider, useTranslation } from 'react-i18next';
import { Global } from '@emotion/react';
import { RKTheme } from '@robinpowered/design-system';
import { Header } from '@robinpowered/dashboard-apps-header';
import { cssReset } from './cssReset';
import { GlobalErrorBoundary } from 'components/GlobalErrorBoundary';
import { config } from 'config';
import {
  useApolloContext,
  useAmplitude,
  useAuthContext,
  ApolloContextProvider,
  AmplitudeProvider,
  AuthContextProvider,
} from 'contexts';
import { PageMessage } from 'components/PageMessage';
import { i18n } from 'i18n';
import {
  ChangeServiceRequestAnswersPage,
  MeetingServicePage,
  RequestingServicePage,
} from 'pages';
import { ConfigProvider, RobinTheme } from '@robinpowered/ui-kit';
import { TicketsListPage } from 'pages/TicketsListPage/TicketsListPage';
import { UpgradePlan } from 'pages/UpgradePlan';
import {
  FeatureFlagProvider,
  useFeatureFlags,
} from 'contexts/FeatureFlagContext';
import { TrackServicesTabLoaded } from 'components/common/TrackServicesTabLoaded';
import { RobinLoader } from 'components/RobinLoader';
import { AccessTokenProvider } from 'contexts/AccessTokenContext/AccessTokenContext';

export const App = (): JSX.Element => {
  return (
    <I18nextProvider i18n={i18n}>
      <GlobalErrorBoundary>
        <Global styles={cssReset} />
        <RKTheme>
          <ConfigProvider theme={{ ...RobinTheme, cssVar: { key: 'tickets' } }}>
            <BrowserRouter>
              <AccessTokenProvider>
                <ApolloContextProvider>
                  <AuthContextProvider>
                    <FeatureFlagProvider>
                      <AmplitudeProvider>
                        <HelmetProvider>
                          <DocumentHead />
                          <RootRouteTree />
                        </HelmetProvider>
                      </AmplitudeProvider>
                    </FeatureFlagProvider>
                  </AuthContextProvider>
                </ApolloContextProvider>
              </AccessTokenProvider>
            </BrowserRouter>
          </ConfigProvider>
        </RKTheme>
      </GlobalErrorBoundary>
    </I18nextProvider>
  );
};

export enum TicketsRoutes {
  SETUP = 'setup',
  TICKETS_LIST = 'list',
  REQUESTING_SERVICE = 'request-form',
  CHANGE_SERVICE_REQUEST_ANSWERS = 'change-answers-form',
}

const RootRouteTree = (): JSX.Element => {
  const { t } = useTranslation(['error', 'MeetingServicePage']);
  const {
    loading: authLoading,
    isLoggedIn,
    error,
    currentOrgHasWSLicenses,
  } = useAuthContext();

  const { checkFlag, loading: featureFlagLoading } = useFeatureFlags();
  const shouldEnforceWorkplaceServiceLicensing = checkFlag(
    'phoenix(enforce-workplace-service-licensing)',
    false
  );

  const showBillingSplashPage =
    shouldEnforceWorkplaceServiceLicensing && !currentOrgHasWSLicenses;

  useEffect(() => {
    /* @TODO: config.env always comes back as "production" */
    const redirectToDashboard =
      !authLoading && !isLoggedIn && config.env !== 'development';
    if (redirectToDashboard) {
      window.location.href = config.dashboardUrl;
    }
  }, [authLoading, isLoggedIn]);

  if (!authLoading && error) {
    return (
      <PageMessage
        title={t('authError.title')}
        message={t('authError.message')}
      />
    );
  }

  return (
    <Routes>
      <Route path="/" element={<span>{t('missing_slug')}</span>} />
      <Route
        path="/"
        element={
          <>
            <HeaderWithParam />
            <TrackServicesTabLoaded />
            <Outlet />
          </>
        }
      >
        {authLoading || featureFlagLoading ? (
          <Route path=":orgSlug/tickets/*" index element={<RobinLoader />} />
        ) : (
          <>
            <Route
              path=":orgSlug/tickets"
              index
              element={
                showBillingSplashPage ? <UpgradePlan /> : <TicketsListPage />
              }
            />

            <Route
              path={`:orgSlug/tickets/${TicketsRoutes.SETUP}`}
              index
              element={<MeetingServicePage />}
            />
            <Route
              path={`:orgSlug/tickets/${TicketsRoutes.TICKETS_LIST}`}
              index
              element={<TicketsListPage />}
            />
          </>
        )}
      </Route>
      <Route
        path={`:orgSlug/tickets/${TicketsRoutes.REQUESTING_SERVICE}`}
        element={
          <>
            <HeaderWithParam headless />
            <RequestingServicePage />
          </>
        }
      />
      <Route
        path={`:orgSlug/tickets/${TicketsRoutes.CHANGE_SERVICE_REQUEST_ANSWERS}`}
        element={
          <>
            <HeaderWithParam headless />
            <ChangeServiceRequestAnswersPage />
          </>
        }
      />
    </Routes>
  );
};

// This is a custom implementation for the Service Request Form, which is consumed
// as a microfrontend in an iframe.  We need to remove the header and redirects so the
// user is not trapped or lost inside the iframe
const HeaderWithParam = ({ headless }: { headless?: boolean }): JSX.Element => {
  const { orgSlug } = useParams<{ orgSlug: string }>();
  const { tenantId } = useApolloContext();
  const { trackEvent } = useAmplitude();
  const { setOrgSlug } = useAuthContext();

  useEffect(() => {
    setOrgSlug(orgSlug);
  }, [orgSlug, setOrgSlug]);

  return (
    <>
      {!headless && (
        <Header
          activeOrgSlug={orgSlug ?? ''}
          hasTenant={!!tenantId}
          amplitudeEventHandler={trackEvent}
          appConfig={{
            featureFlagUrl: config.featureFlagUrl,
            sessionCookieName: config.sessionCookieName,
          }}
        />
      )}
    </>
  );
};

export const DocumentHead = (): JSX.Element => {
  const { t } = useTranslation('common');
  return (
    <Helmet>
      <title>{t('default_page_title')}</title>
    </Helmet>
  );
};
