import styled from '@emotion/styled';
import { SelectLocations } from '../../common/SelectedLocations/SelectLocations';
import { useMeetingServicePageContext } from 'pages/MeetingServicePage/contexts/MeetingServicePageContext';
export const MeetingServicesFilters = () => {
  const {
    selectedLocationIds,
    setSelectedLocationIds,
    loadingLocations,
    organizationLocations,
  } = useMeetingServicePageContext();

  return (
    <MeetingServiceFilterWrapper>
      <SelectLocations
        onSelectLocation={setSelectedLocationIds}
        selectedLocations={selectedLocationIds}
        organizationLocations={organizationLocations}
        loading={loadingLocations}
      />
    </MeetingServiceFilterWrapper>
  );
};

const MeetingServiceFilterWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
`;
