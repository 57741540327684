import { Flex, Typography } from '@robinpowered/ui-kit';

type PropTypes = {
  title: string;
  message?: string;
};

export const PageMessage = ({ title, message }: PropTypes): JSX.Element => {
  return (
    <Flex align="center" justify="center" vertical style={{ height: '100%' }}>
      <Typography.Title type="secondary" level={2}>
        {title}
      </Typography.Title>
      {message && (
        <Typography.Paragraph type="secondary">{message}</Typography.Paragraph>
      )}
    </Flex>
  );
};
