import {
  QuestionContextProvider,
  ServiceQuestion,
} from 'components/ServiceRequest/components/QuestionTypes/contexts/QuestionContext';

import { Answers } from '../types/ServiceRequestTypes';
import { Dispatch, SetStateAction } from 'react';
import { QuestionSelect } from './QuestionTypes/Components/QuestionSelect';
import { LongAnswer } from './QuestionTypes/Components/LongAnswer';
import { ShortAnswer } from './QuestionTypes/Components/ShortAnswer';
import { QuestionFileUpload } from './QuestionTypes/Components/QuestionFileUpload/QuestionFileUpload';
import { useFeatureFlags } from 'contexts/FeatureFlagContext';

export const ServiceQuestions = ({
  serviceQuestions,
  answers,
  setAnswers,
  setErrors,
  errors,
}: {
  serviceQuestions: ServiceQuestion[];
  answers: Answers;
  setAnswers: Dispatch<SetStateAction<Answers>>;
  setErrors: (questionId: string, hasError: boolean) => void;
  errors: Map<string, boolean>;
}) => {
  const { checkFlag } = useFeatureFlags();
  const shouldShowFileUpload = checkFlag('phoenix(ticket-file-uploads)', false);

  const renderQuestion = (question: ServiceQuestion) => {
    switch (question.__typename) {
      case 'TicketQuestionExpectingChoices':
      case 'TicketQuestionExpectingMenuChoices': {
        return <QuestionSelect key={question.id} />;
      }
      case 'TicketQuestionExpectingText': {
        const TextAnswer = question.multilineAnswer ? LongAnswer : ShortAnswer;
        return <TextAnswer key={question.id} />;
      }
      case 'TicketQuestionExpectingFiles': {
        return shouldShowFileUpload ? (
          <QuestionFileUpload key={question.id} />
        ) : null;
      }
      default:
        return null;
    }
  };

  return (
    <>
      {serviceQuestions.map((question) => {
        return (
          <QuestionContextProvider
            key={question.id}
            question={question}
            answers={answers}
            hasError={!!errors.get(question.id)}
            setHasError={setErrors}
            setAnswers={setAnswers}
          >
            {renderQuestion(question)}
          </QuestionContextProvider>
        );
      })}
    </>
  );
};
