import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { eventTitleCases, formatCurrency, obfuscateTitle } from 'lib/utility';
import {
  GetServiceRequestByIdForDetailSidebarQuery,
  MeetingServiceRequestStatus,
} from 'generated';
import { useServiceDetailsSidebarContext } from '../contexts/ServiceDetailsSidebarContext';
import moment from 'moment';

type ServiceDetailsType =
  | GetServiceRequestByIdForDetailSidebarQuery['getMeetingServiceRequestByIdentifier']['meetingServiceRequest']
  | undefined;

type RowType<T = string> = {
  rowTitle: string;
  rowValue: T;
};

export type ServiceDetailsProps = {
  status?: RowType<MeetingServiceRequestStatus> | undefined;
  requester?:
    | RowType<{
        name: string;
        email: string;
        avatar?: string;
      }>
    | undefined;
  eventStartTime?:
    | RowType<{
        date: string;
        time: string;
      }>
    | undefined;
  location?:
    | RowType<{
        spaceName: string;
        locationName: string;
      }>
    | undefined;
  eventTitle?: RowType | undefined;
  category?: RowType | undefined;
  serviceName?: RowType | undefined;
  attendees?: RowType<number> | undefined;
  assignees?: RowType<string[]> | undefined;
  key?: RowType | undefined;
};

export const useManageDownloadTicketPdf = () => {
  const { t } = useTranslation('common');
  const { t: tServiceDetailsSidebar } = useTranslation('ServiceDetailsSidebar');
  const { t: tTicketsListPage } = useTranslation('TicketsListPage');
  const { serviceDetails, locationData, loading } =
    useServiceDetailsSidebarContext();

  const locationName = useMemo(() => {
    return [
      locationData?.getLevelById?.name,
      locationData?.getLocationById?.name,
    ]
      .filter((x) => x)
      .join(', ');
  }, [locationData]);

  const totalPrice = useMemo(() => {
    const totalPriceValue =
      serviceDetails && serviceDetails.totalPrice
        ? formatCurrency(serviceDetails.totalPrice)
        : null;
    return totalPriceValue
      ? {
          rowTitle: tServiceDetailsSidebar('footer.total'),
          rowValue: totalPriceValue,
        }
      : null;
  }, [serviceDetails, tServiceDetailsSidebar]);

  const getServiceDetailsProps = (
    serviceDetails: ServiceDetailsType
  ): ServiceDetailsProps => {
    if (!serviceDetails) {
      return {};
    }

    return {
      ...(serviceDetails.meetingServiceRequestStatus && {
        status: {
          rowTitle: t('detail_card.status'),
          rowValue: tTicketsListPage(
            `tickets.status.${serviceDetails.meetingServiceRequestStatus.toLowerCase()}`
          ),
        },
      }),
      ...(serviceDetails.requester && {
        requester: {
          rowTitle: t('detail_card.requested'),
          rowValue: {
            name: serviceDetails.requester.name || '',
            email: serviceDetails.requester.primaryEmail?.email || '',
            avatar: serviceDetails.requester.avatar || '',
          },
        },
      }),
      ...(serviceDetails.regardsEventAtSpace?.eventStart && {
        eventStartTime: {
          rowTitle: t('detail_card.due'),
          rowValue: {
            date: moment(serviceDetails.regardsEventAtSpace.eventStart).format(
              'ddd, MMM DD, YYYY'
            ),
            time: moment(serviceDetails.regardsEventAtSpace.eventStart).format(
              'h:mm a'
            ),
          },
        },
      }),
      ...(serviceDetails.regardsEventAtSpace?.space?.name || locationName
        ? {
            location: {
              rowTitle: t('detail_card.location'),
              rowValue: {
                spaceName:
                  serviceDetails.regardsEventAtSpace?.space?.name || '',
                locationName: locationName,
              },
            },
          }
        : undefined),
      ...(serviceDetails.regardsEventAtSpace?.event?.title && {
        eventTitle: {
          rowTitle: t('detail_card.event_title'),
          rowValue: obfuscateTitle(
            eventTitleCases({
              visibility:
                serviceDetails.regardsEventAtSpace?.event?.visibility || '',
              title: serviceDetails.regardsEventAtSpace?.event?.title || '',
            }),
            {
              confidential: tTicketsListPage('csv.event_title_confidential'),
              missing: tTicketsListPage('csv.event_title_missing'),
            }
          ),
        },
      }),
      ...(serviceDetails.meetingService?.category?.name && {
        category: {
          rowTitle: t('detail_card.category'),
          rowValue: serviceDetails.meetingService.category.name,
        },
      }),
      ...(serviceDetails.meetingService?.name && {
        serviceName: {
          rowTitle: t('detail_card.service_name'),
          rowValue: serviceDetails.meetingService.name,
        },
      }),
      ...(serviceDetails.regardsEventAtSpace?.event?.invitees?.length && {
        attendees: {
          rowTitle: t('detail_card.attendees'),
          rowValue: serviceDetails.regardsEventAtSpace.event.invitees.length,
        },
      }),
      ...(serviceDetails.assignees?.usersAndGroups?.length && {
        assignees: {
          rowTitle: t('detail_card.assignees'),
          rowValue: serviceDetails.assignees.usersAndGroups.map((a) =>
            a?.__typename === 'User'
              ? a.userName
              : a?.__typename === 'Group'
              ? a.groupName
              : ''
          ),
        },
      }),
      ...(serviceDetails.key && {
        key: {
          rowTitle: t('detail_card.key'),
          rowValue: serviceDetails.key,
        },
      }),
    } as ServiceDetailsProps;
  };

  return {
    loading: loading,
    totalPrice,
    getServiceDetailsProps,
  };
};
