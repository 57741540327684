import { useMemo, useEffect } from 'react';
import { useHttpState } from './useHttpState';

export type FeatureFlags =
  | 'phoenix(enforce-workplace-service-licensing)'
  | 'phoenix(ticket-file-uploads)';
type FeatureFlagUnleashedValue = Record<string, unknown>;

interface UnleashClient {
  getFlags: (
    accessToken: string,
    tenantId: string
  ) => Promise<FeatureFlagUnleashedValue>;
}

const createUnleashClient = (baseURL: string): UnleashClient => {
  return {
    getFlags: async (
      accessToken: string,
      tenantId: string
    ): Promise<FeatureFlagUnleashedValue> => {
      const response = await fetch(`${baseURL}`, {
        method: 'POST',
        headers: {
          'content-type': 'application/x-www-form-urlencoded',
        },
        body: new URLSearchParams({
          accessToken,
          userProperties: JSON.stringify({
            orgId: tenantId,
          }),
        }),
      });

      if (!response.ok) {
        throw new Error(
          `Failed to fetch feature flags: ${response.status} ${response.statusText}`
        );
      }

      const data = (await response.json()) as unknown;

      // Type guard to verify the response matches FeatureFlagUnleashedValue
      if (typeof data === 'object' && data !== null) {
        return Object.fromEntries(
          Object.entries(data).map(([k, v]) => {
            if (typeof v === 'boolean') {
              return [k, v] as const;
            }
            throw new Error('Invalid feature flag response format');
          })
        );
      }

      throw new Error('Invalid feature flag response format');
    },
  };
};

const useUnleashClient = (baseUrl: string): UnleashClient => {
  return useMemo(() => createUnleashClient(baseUrl), [baseUrl]);
};

type useFeatureFlagsReturn = {
  data: FeatureFlagUnleashedValue | null;
  error: unknown | null;
  loading: boolean;
};

export const useFeatureFlags = (
  serviceUrl: string,
  accessToken: string | null,
  tenantId: string | null
): useFeatureFlagsReturn => {
  const client = useUnleashClient(serviceUrl);
  const { data, error, loading, setLoading, setData, setError } =
    useHttpState<FeatureFlagUnleashedValue | null>();

  useEffect(() => {
    if (!tenantId || !accessToken) {
      return;
    }

    setLoading(true);
    const controller = new AbortController();

    client
      .getFlags(accessToken, tenantId)
      .then((response) => {
        setData(response);
      })
      .catch((error: Error) => {
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });

    return (): void => {
      controller.abort();
      setLoading(false);
    };
  }, [tenantId, accessToken, client, setData, setError, setLoading]);

  return {
    data,
    error,
    loading,
  };
};
