import { useQuestionContext } from 'components/ServiceRequest/components/QuestionTypes/contexts/QuestionContext';

import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export const useQuestionSelect = () => {
  const { t } = useTranslation('MeetingServiceRequest');

  const {
    selectType,
    id,
    required,
    answer,
    setHasError,
    hasError,
    setAnswers,
  } = useQuestionContext();

  const isOptionSelected = useCallback(
    (optionId: string) => {
      return !!(
        answer &&
        answer?.type === 'choices' &&
        answer.options.has(optionId)
      );
    },
    [answer]
  );

  const handleUpdateQuantity = useCallback(
    (selectedOptionId: string, quantity: number | null) => {
      setAnswers((prev) => {
        const prevAnswers = structuredClone(prev);
        const prevAnswer = prevAnswers.get(id);

        if (prevAnswer) {
          if (selectType === 'multi' && prevAnswer.type === 'choices') {
            prevAnswer.options.set(selectedOptionId, {
              quantity,
            });

            return prevAnswers;
          }
        }

        prevAnswers.set(id, {
          type: 'choices',
          options: new Map([[selectedOptionId, { quantity }]]),
        });

        return prevAnswers;
      });
    },
    [id, selectType, setAnswers]
  );

  const getOptionQuantity = useCallback(
    (optionId: string) => {
      const option =
        answer?.type === 'choices' ? answer.options.get(optionId) : undefined;

      // Defaulting the base number to 1
      return option && option.quantity ? option.quantity : 1;
    },
    [answer]
  );

  const handleSelectOption = useCallback(
    (selectedOptionId: string, quantity: number | null) => {
      setHasError(id, false);

      setAnswers((prev) => {
        const prevAnswers = structuredClone(prev);
        const prevAnswer = prevAnswers.get(id);

        if (prevAnswer) {
          if (selectType === 'multi' && prevAnswer.type === 'choices') {
            if (isOptionSelected(selectedOptionId)) {
              prevAnswer.options.delete(selectedOptionId);

              if (prevAnswer.options.size === 0) {
                required
                  ? prevAnswers.delete(id)
                  : prevAnswers.set(id, { type: 'unanswered' });
              }

              return prevAnswers;
            }

            prevAnswer.options.set(selectedOptionId, {
              quantity,
            });

            return prevAnswers;
          }
        }

        prevAnswers.set(id, {
          type: 'choices',
          options: new Map([[selectedOptionId, { quantity }]]),
        });

        return prevAnswers;
      });
    },
    [id, isOptionSelected, required, selectType, setAnswers, setHasError]
  );

  const error = useMemo(
    () => (hasError && required ? t('errors.required') : undefined),
    [hasError, required, t]
  );

  return {
    handleSelectOption,
    handleUpdateQuantity,
    getOptionQuantity,
    isOptionSelected,
    error,
  };
};
