import { MeetingService } from 'components/ServiceRequest/types/ServiceRequestTypes';
import { useAuthContext } from 'contexts';
import {
  useGetMeetingServiceAvailableInSpaceQuery,
  useGetMeetingServiceByIdForServiceRequestQuery,
} from 'generated';
import { groupBy, uniqBy } from 'lib/utility';
import { useCallback, useMemo, useState } from 'react';

type CategoryOption = {
  value: string;
  label: string;
  id: string;
};

export const useSelectedCategory = (spaceId: string | undefined) => {
  const [selectedCategory, setSelectedCategory] = useState<CategoryOption>({
    value: '',
    label: '',
    id: '',
  });
  const { loading: authLoading } = useAuthContext();
  const [selectedServiceWithoutQuestions, setSelectServiceWithoutQuestions] =
    useState<MeetingService | undefined>();

  const selectService = (serviceId: string) => {
    const selectedService = services.find(
      (service) => service.id === serviceId
    );
    if (selectedService) {
      setSelectServiceWithoutQuestions(selectedService);
    }
  };

  const { data } = useGetMeetingServiceAvailableInSpaceQuery({
    variables: {
      input: {
        filters: { spaceId: spaceId || '' },
        first: 100,
      },
    },
    skip: !spaceId || authLoading,
  });

  const meetingServices = useMemo(() => {
    return data?.listMeetingServicesAvailableInSpace?.meetingServices || [];
  }, [data]);

  /* Pull out all the unique Categories for the category selector */
  const categories = useMemo(() => {
    return uniqBy(meetingServices, (service) => service.category.id).map(
      (service) => ({
        value: service.category.name,
        label: service.category.name,
        id: service.category.id,
      })
    );
  }, [meetingServices]);

  const uniqueCategoriesWithServices = groupBy(
    meetingServices,
    (service) => service.category.id
  );

  const services = useMemo(
    () => uniqueCategoriesWithServices[selectedCategory.id] || [],
    [selectedCategory.id, uniqueCategoriesWithServices]
  );

  const handleSetCategory = useCallback((category: CategoryOption) => {
    setSelectedCategory(category);
    setSelectServiceWithoutQuestions(undefined);
  }, []);

  /* Get the service that was selected */
  const { data: serviceWithQuestions, loading: gettingServiceWithQuestions } =
    useGetMeetingServiceByIdForServiceRequestQuery({
      variables: {
        getMeetingServiceByIdId: selectedServiceWithoutQuestions?.id || '',
      },
      skip: !selectedServiceWithoutQuestions?.id,
    });

  return {
    serviceWithQuestions,
    selectedServiceWithoutQuestions,
    categories,
    selectCategory: handleSetCategory,
    selectService,
    services,
    selectedCategory,
    gettingServiceWithQuestions,
  };
};
