import { Grid, Pagination as RobinPagination } from '@robinpowered/ui-kit';
import { useTicketsListTableContext } from 'components/TicketsListTable/contexts';
import { ITEMS_PER_TABLE_PAGE } from 'pages/MeetingServicePage/contexts/MeetingServicePageContext';
import { useTicketsListPageContext } from 'pages/TicketsListPage/contexts/TicketsListPageContext';
import { useTranslation } from 'react-i18next';

export const Pagination = (): JSX.Element | null => {
  const { t } = useTranslation('TicketsListPage');
  const { meetingServiceRequestsCount } = useTicketsListPageContext();
  const { handlePageNumberClick, activePage } = useTicketsListTableContext();
  const { useBreakpoint } = Grid;
  const { lg } = useBreakpoint();

  return (
    <RobinPagination
      current={activePage}
      simple={!lg}
      total={meetingServiceRequestsCount}
      showTotal={(total) => t('table.pagination.total', { count: total })}
      pageSize={ITEMS_PER_TABLE_PAGE}
      onChange={handlePageNumberClick}
      hideOnSinglePage={meetingServiceRequestsCount <= ITEMS_PER_TABLE_PAGE}
      showSizeChanger={false}
    />
  );
};
