import {
  createContext,
  useContext,
  FC,
  useState,
  useEffect,
  Dispatch,
  SetStateAction,
  useMemo,
} from 'react';
import moment from 'moment';
import { useGetMeetingServiceRequestByIdForEditingServiceRequestQuery } from 'generated';
import { useGetServiceRequestDetails } from '../hooks/useGetServiceRequestDetails';
import { useConnectToParent } from '../hooks/useConnectToParent';
import { useValidateData } from '../../hooks/useValidateData';
import {
  Answers,
  ChangeAnswersMeetingServiceRequest,
  ChangeAnswersMeetingServiceRequestAssignees,
  ChangeAnswersMeetingServiceRequestQuestion,
  MeetingServiceEvent,
} from '../../types/ServiceRequestTypes';
import { getTotalPrice } from '../../utils';
import { useAuthContext } from 'contexts/AuthContext';

type ChangeServiceRequestAnswersContextValue = {
  requestedBy:
    | {
        name?: string | null | undefined;
        avatar?: string | null | undefined;
      }
    | undefined;
  assignedTo: string[];
  loading: boolean;
  assignees: ChangeAnswersMeetingServiceRequestAssignees;
  serviceDescription: string | null | undefined;
  serviceRequestAnswers: Answers;
  serviceName: string | null | undefined;
  categoryName: string | null | undefined;
  setServiceRequestAnswers: Dispatch<SetStateAction<Answers>>;
  errors: Map<string, boolean>;
  handleAnswerError: (questionId: string, error: boolean) => void;
  isRecurring: boolean | null | undefined;
  questions: ChangeAnswersMeetingServiceRequestQuestion[] | undefined;
} & MeetingServiceEvent;

const ChangeServiceRequestAnswersContext =
  createContext<ChangeServiceRequestAnswersContextValue>({
    requestedBy: undefined,
    assignedTo: [],
    locationName: '',
    dueDate: '',
    numOfAttendees: 0,
    loading: true,
    assignees: undefined,
    serviceDescription: '',
    serviceName: '',
    categoryName: '',
    serviceRequestAnswers: new Map(),
    setServiceRequestAnswers: () => null,
    errors: new Map(),
    handleAnswerError: () => null,
    isRecurring: false,
    questions: [],
  });

export const ChangeServiceRequestAnswersContextProvider: FC = ({
  children,
}) => {
  const { loading: authLoading } = useAuthContext();
  const [meetingServiceRequest, setMeetingServiceRequest] =
    useState<ChangeAnswersMeetingServiceRequest>(undefined);

  const [serviceRequestAnswers, setServiceRequestAnswers] = useState<Answers>(
    () => new Map()
  );
  const completedFormQuestions = useMemo(
    () =>
      meetingServiceRequest?.completedForm?.questions.map((q) => q.question),
    [meetingServiceRequest?.completedForm?.questions]
  );

  const { areAnswersValid, errors, handleAnswerError } = useValidateData(
    completedFormQuestions || []
  );

  const totalPrice = useMemo(() => {
    return getTotalPrice(
      serviceRequestAnswers,
      completedFormQuestions || [],
      meetingServiceRequest?.meetingService?.currencyCode
    );
  }, [serviceRequestAnswers, completedFormQuestions, meetingServiceRequest]);

  const { initialValues } = useConnectToParent(
    {
      previousCompletedFormId: meetingServiceRequest?.completedForm?.id,
      meetingServiceQuestions: completedFormQuestions,
      answers: serviceRequestAnswers,
      totalPrice,
    },
    () => {
      return areAnswersValid(serviceRequestAnswers);
    }
  );

  const { data: editingServiceRequest, loading: editingServiceRequestLoading } =
    useGetMeetingServiceRequestByIdForEditingServiceRequestQuery({
      variables: {
        identifier: {
          id: initialValues?.formAction?.serviceRequestId,
        },
      },
      // Set this to network-only to ensure we receive the latest
      // completedFormId from the server.  This allows us to
      // check if another user has changed answers before the current user has saved the form
      fetchPolicy: 'network-only',
      skip: !initialValues?.formAction?.serviceRequestId || authLoading,
    });

  const { locationName, loading } = useGetServiceRequestDetails({
    spaceName: meetingServiceRequest?.regardsEventAtSpace?.space?.name,
    locationId:
      meetingServiceRequest?.regardsEventAtSpace?.space?.locationId.toString(),
    levelId:
      meetingServiceRequest?.regardsEventAtSpace?.space?.levelId?.toString(),
  });

  const requestedBy = useMemo(() => {
    return meetingServiceRequest?.requester
      ? {
          name: meetingServiceRequest.requester.name,
          avatar: meetingServiceRequest.requester.avatar,
        }
      : undefined;
  }, [meetingServiceRequest?.requester]);

  useEffect(() => {
    if (!editingServiceRequest) return;

    setMeetingServiceRequest(
      editingServiceRequest.getMeetingServiceRequestByIdentifier
        .meetingServiceRequest
    );

    const answers =
      editingServiceRequest.getMeetingServiceRequestByIdentifier.meetingServiceRequest?.completedForm?.questions.map(
        (q) => {
          switch (q.__typename) {
            case 'AnsweredTicketQuestionExpectingMenuChoices':
              return {
                key: q.question.id,
                value: {
                  type: 'choices' as const,
                  options: new Map(
                    q.choices.map((c) => {
                      if (c.__typename === 'TicketAnswerMenuChoice') {
                        return [
                          c.option.id,
                          {
                            quantity: c.quantity ?? null,
                          },
                        ];
                      }
                      return [c.option.id, { quantity: null }];
                    })
                  ),
                },
              };

            case 'AnsweredTicketQuestionExpectingFiles':
              return {
                key: q.question.id,
                value: {
                  type: 'file' as const,
                  files: q.files.map((f) => ({
                    id: f.id,
                    name: f.name,
                  })),
                },
              };

            case 'AnsweredTicketQuestionExpectingChoices':
              return {
                key: q.question.id,
                value: {
                  type: 'choices' as const,
                  options: new Map(
                    q.choices.map((c) => {
                      return [c.id, { quantity: null }];
                    })
                  ),
                },
              };

            case 'AnsweredTicketQuestionExpectingText':
              return {
                key: q.question.id,
                value: { type: 'text' as const, text: q.answer },
              };
            case 'UnansweredTicketQuestion':
            case undefined:
              return null;
            default: {
              // Fun typescript so we handle all cases of q.__typename
              const _exhaustiveCheck: never = q;
              return _exhaustiveCheck;
            }
          }
        }
      );

    setServiceRequestAnswers((prev) => {
      const prevCopy = structuredClone(prev);
      answers?.forEach((a) => {
        if (a) {
          prevCopy.set(a.key, a.value);
        }
      });
      return prevCopy;
    });
  }, [editingServiceRequest]);

  return (
    <ChangeServiceRequestAnswersContext.Provider
      value={{
        requestedBy,
        assignedTo: [],
        serviceName: meetingServiceRequest?.meetingService?.name,
        categoryName: meetingServiceRequest?.meetingService?.category.name,
        assignees: meetingServiceRequest?.meetingService?.assignees,
        questions: completedFormQuestions,
        serviceDescription: meetingServiceRequest?.meetingService?.description,
        dueDate: moment(
          meetingServiceRequest?.regardsEventAtSpace?.eventStart
        ).format('ddd, MMM DD, h:mm a'),
        locationName,
        numOfAttendees:
          meetingServiceRequest?.regardsEventAtSpace?.event?.invitees.length ||
          1,
        loading: loading || editingServiceRequestLoading,
        serviceRequestAnswers,
        setServiceRequestAnswers,
        errors,
        handleAnswerError,
        isRecurring: initialValues?.isRecurring,
      }}
    >
      {children}
    </ChangeServiceRequestAnswersContext.Provider>
  );
};

export const useChangeServiceRequestAnswersContext = () => {
  return useContext(ChangeServiceRequestAnswersContext);
};
