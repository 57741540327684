import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useMemo,
  FC,
} from 'react';
import { useAuthContext } from './AuthContext';
import Amplitude, { AmplitudeClient } from 'amplitude-js';
import { config } from 'config';
import { AmplitudeEvents } from 'types/amplitude';
import { Sentry } from 'lib/sentry';

type BufferedEvent = {
  eventName: string;
  metadata?: Record<string, unknown>;
};

type AmplitudeContextValue = {
  trackEvent: (eventName: string, metadata?: Record<string, unknown>) => void;
  flushEvents: () => Promise<void>;
};

export const AmplitudeContext = createContext<AmplitudeContextValue>({
  trackEvent: () =>
    // eslint-disable-next-line no-console
    console.warn(
      'Tried to send an Amplitude event before AmplitudeContext is ready'
    ),
  flushEvents: () => Promise.resolve(),
});

const log = (message: string, ...args: unknown[]) => {
  if (config.robinEnv === 'development') {
    console.log(`[Amplitude] ${message}`, ...args); // eslint-disable-line no-console
  }
};
export const AmplitudeProvider: FC = ({ children }) => {
  const bufferedEventsRef = useRef<BufferedEvent[]>([]);
  const { currentUser, currentOrg } = useAuthContext();
  const identifiedRef = useRef(false);

  const client = useMemo<AmplitudeClient>(() => {
    const client = Amplitude.getInstance('robin-product-metrics');
    client.init(config.amplitudeApiKey);
    return client;
  }, []);

  // We host a page of tickets app as a microfrontend inside of an iframe in a sidebar.
  // When we successfully create a service request from that side bar, we close it.
  // When we close it, it cancels amplitude trackEvents that have not finished
  // This forces amplitude to flush events and blocks js to wait for the client to finish the request
  const flushEvents = useCallback(async () => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (client as any).sendEvents();

    let loopCount = 0;
    // Limit the loop to 100 cycles before bailing
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    while ((client as any)._sending === true && loopCount < 50) {
      if (loopCount === 49) {
        Sentry.captureException(
          'Amplitude failed to resolve flushEvents - see AmplitudeContext.'
        );
      }
      await new Promise((resolve) => setTimeout(resolve, 100));
      loopCount++;
    }
  }, [client]);

  useEffect(() => {
    if (!currentUser || !currentOrg) {
      return;
    }

    // Identify the user
    const identify = new Amplitude.Identify();
    identify.set('organizationId', currentOrg.id);
    if (currentOrg.slug) {
      identify.set('organizationSlug', currentOrg.slug);
    }
    client.setUserId(currentUser.id);
    client.setGroup('organizationId', currentOrg.id);
    client.identify(identify);
    identifiedRef.current = true;
    log('Client identity set');

    // Flush events that came in before Amplitude had time to initialize
    if (bufferedEventsRef.current.length) {
      log('Flushing buffered events', bufferedEventsRef.current);
      bufferedEventsRef.current.forEach(({ eventName, metadata }) => {
        client.logEvent(eventName, metadata);
      });
      bufferedEventsRef.current = [];
    }
  }, [currentUser, currentOrg, client]);

  const trackEvent = useCallback(
    (eventName: string, metadata?: Record<string, unknown>) => {
      metadata = { application: 'services', ...metadata };
      if (!identifiedRef.current) {
        log('Buffering event:', { eventName, metadata });
        bufferedEventsRef.current.push({ eventName, metadata });
      } else {
        log('Tracking event:', { eventName, metadata });
        client.logEvent(eventName, metadata);
      }
    },
    [identifiedRef, bufferedEventsRef, client]
  );

  return (
    <AmplitudeContext.Provider value={{ trackEvent, flushEvents }}>
      {children}
    </AmplitudeContext.Provider>
  );
};

type UseAmplitude = () => {
  /**
   * Track when a user visits the services tab.
   */
  trackServicesTabLoaded: () => void;
  trackEvent: (event: string, metadata?: Record<string, unknown>) => void;
  flushEvents: () => Promise<void>;
};

export const useAmplitude: UseAmplitude = () => {
  const { trackEvent, flushEvents } = useContext(AmplitudeContext);

  const trackServicesTabLoaded = useCallback(() => {
    trackEvent(AmplitudeEvents.LOADED_DASHBOARD_TAB, {
      tab: 'services',
    });
  }, [trackEvent]);

  return {
    trackEvent,
    trackServicesTabLoaded,
    flushEvents,
  };
};
