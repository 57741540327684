import { useCallback, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

export const useExternalAccessToken = () => {
  const [searchParams] = useSearchParams();
  const [accessToken, setAccessToken] = useState<string | null | undefined>(
    undefined
  );
  const setExternalAccessToken = useCallback(
    (token: string | null | undefined) => {
      setAccessToken(token ?? null);
    },
    []
  );
  // True when we've completed the external token fetch attempt (regardless of whether we got a token or not)
  const isExternalTokenReady = accessToken !== undefined;

  // When consuming tickets app from an iframe other than robin dashboard(i.e. the outlook extension app),
  // the parent may need to give us the auth token and we cannot rely on the cookie since some
  // auth flows may not set it for us
  const useParentTokenParam = searchParams.get('use_parent_token');
  const shouldUseParentToken = useMemo(() => {
    return useParentTokenParam === 'true';
  }, [useParentTokenParam]);

  return {
    externalAccessToken: accessToken,
    setExternalAccessToken,
    isExternalTokenReady,
    shouldUseParentToken,
  };
};
