import {
  createContext,
  useContext,
  FC,
  ReactNode,
  Dispatch,
  SetStateAction,
  useEffect,
} from 'react';
import {
  Exact,
  GetMeetingServiceByIdForEditScreenQuery,
  GetMeetingServicesForAdminTableListQuery,
  InputMaybe,
  ListMeetingServicesFiltersInput,
  ListMeetingServicesSortByInput,
  useGetMeetingServiceByIdForEditScreenQuery,
  useGetMeetingServicesCountForTablePaginationQuery,
  useGetMeetingServicesForAdminTableListQuery,
} from 'generated';
import { useTenantLocalStorage } from 'hooks/useTenantLocalStorage';
import { useManageMeetingServicesLocations } from 'hooks/useManageMeetingServicesLocations';
import { useAuthContext } from 'contexts/AuthContext';
import { QueryResult } from '@apollo/client';
import { useToggleServiceForm } from '../hooks/useToggleServiceForm';

const SELECTED_LOCATIONS = 'selected-locations';

type RefetchFilters = {
  skip?: InputMaybe<number> | undefined;
  after?: string | null | undefined;
};
export type MeetingServicesValue = {
  meetingServicesData: GetMeetingServicesForAdminTableListQuery | undefined;
  refetchMeetingServices: (filters?: RefetchFilters) => void;
  meetingServicesCount: number;
  refetchMeetingServicesCount: () => void;
  loadingMeetingServices: boolean;
  filtersForMeetingServicesQuery: ListMeetingServicesFiltersInput | undefined;
  setFiltersForMeetingServicesQuery: Dispatch<
    SetStateAction<ListMeetingServicesFiltersInput | undefined>
  >;
  sortByForMeetingServicesQuery:
    | ListMeetingServicesSortByInput
    | undefined
    | null;
  setSortByForMeetingServicesQuery: Dispatch<
    SetStateAction<ListMeetingServicesSortByInput | undefined | null>
  >;
  selectedLocationIds: string[];
  setSelectedLocationIds: (value: string[]) => void;
  loadingLocations: boolean;
  organizationLocations: {
    id: string;
    name: string;
  }[];
  getMeetingServiceByIdQuery:
    | QueryResult<
        GetMeetingServiceByIdForEditScreenQuery,
        Exact<{
          getMeetingServiceByIdId: string;
        }>
      >
    | undefined;
  serviceId: string;
  serviceFormOpen: boolean;
  setServiceFormOpen: (open: boolean) => void;
  openServiceFormWithId: (serviceId: string) => void;
};

export const MeetingServicePageContext = createContext<MeetingServicesValue>({
  meetingServicesData: undefined,
  refetchMeetingServices: () => null,
  meetingServicesCount: 0,
  refetchMeetingServicesCount: () => null,
  loadingMeetingServices: false,
  filtersForMeetingServicesQuery: undefined,
  setFiltersForMeetingServicesQuery: () => null,
  sortByForMeetingServicesQuery: undefined,
  setSortByForMeetingServicesQuery: () => null,
  selectedLocationIds: [],
  setSelectedLocationIds: () => null,
  loadingLocations: false,
  organizationLocations: [],
  getMeetingServiceByIdQuery: undefined,
  serviceId: '',
  serviceFormOpen: false,
  setServiceFormOpen: () => null,
  openServiceFormWithId: () => null,
});

type Props = {
  children: ReactNode;
};

export const ITEMS_PER_TABLE_PAGE = 15;

export const MeetingServicePageContextProvider: FC<Props> = ({ children }) => {
  const { loading: loadingAuth } = useAuthContext();

  const {
    serviceId,
    serviceFormOpen,
    setServiceFormOpen,
    openServiceFormWithId,
  } = useToggleServiceForm();
  // TODO: if user does not have permission to manage services in any building
  // then we should redirect away or show error message
  const {
    loading: loadingLocations,
    locationsUserCanManage,
    organizationLocations,
  } = useManageMeetingServicesLocations();

  // FYI: at one point we were defaulting this to the user's managed locations if they had
  // nothing selected. However, it is important for us to be able to pass in empty []
  // because it allows us to see meeting services that aren't available in any spaces
  const [selectedLocationIds = [], setSelectedLocationIds] =
    useTenantLocalStorage<string[]>(SELECTED_LOCATIONS, []);

  useEffect(() => {
    // If the local storage is empty, then we want to set the default
    // selected locations to the locations the user can manage
    if (
      !loadingLocations &&
      locationsUserCanManage?.length > 0 &&
      selectedLocationIds.length === 0
    ) {
      setSelectedLocationIds(
        locationsUserCanManage.map((location) => location?.id || '')
      );
    }
    // We rely on the initial selectedLocationIds length to be 0 on first render
    // so that we can set a default value for the selected locations
    // we do not want this to run every time selectedLocationIds changes so we omit in the dependency array
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationsUserCanManage, setSelectedLocationIds, loadingLocations]);

  const [filtersForMeetingServicesQuery, setFiltersForMeetingServicesQuery] =
    useTenantLocalStorage<ListMeetingServicesFiltersInput>(
      'meeting-service-admin-table-filters',
      {
        belongsToAnyCategoryId: [],
        assignedToAny: {
          userIds: [],
          groupIds: [],
        },
      }
    );

  const [sortByForMeetingServicesQuery, setSortByForMeetingServicesQuery] =
    useTenantLocalStorage<ListMeetingServicesSortByInput | null>(
      'meeting-service-admin-table-sort',
      null
    );

  const {
    data: meetingServicesData,
    refetch: refetchMeetingServices,
    loading,
  } = useGetMeetingServicesForAdminTableListQuery({
    variables: {
      input: {
        first: ITEMS_PER_TABLE_PAGE,
        filters: {
          ...filtersForMeetingServicesQuery,
          availableInAnyBuildingIdsOrTheirDescendants: selectedLocationIds,
        },
        sortBy: sortByForMeetingServicesQuery
          ? sortByForMeetingServicesQuery
          : undefined,
      },
    },
    skip: loadingLocations,
    notifyOnNetworkStatusChange: true,
  });

  // @TODO: Will need to pass in Kunle's data here
  const { data, refetch: refetchMeetingServicesCount } =
    useGetMeetingServicesCountForTablePaginationQuery({
      variables: {
        countMeetingInput: {
          filters: {
            ...filtersForMeetingServicesQuery,
            availableInAnyBuildingIdsOrTheirDescendants: selectedLocationIds,
          },
        },
      },
      skip: loadingLocations,
    });

  const getMeetingServiceByIdQuery = useGetMeetingServiceByIdForEditScreenQuery(
    {
      variables: {
        getMeetingServiceByIdId: serviceId,
      },
      skip: !serviceId || loadingAuth,
    }
  );

  return (
    <MeetingServicePageContext.Provider
      value={{
        meetingServicesData,
        refetchMeetingServices: (filters?: RefetchFilters) => {
          refetchMeetingServices({
            input: {
              first: ITEMS_PER_TABLE_PAGE,
              filters: filtersForMeetingServicesQuery,
              sortBy: sortByForMeetingServicesQuery || undefined,
              skip: filters?.skip,
              after: filters?.after,
            },
          });
        },
        meetingServicesCount: data?.countMeetingServices.count || 0,
        refetchMeetingServicesCount: () => {
          refetchMeetingServicesCount({
            countMeetingInput: {
              filters: filtersForMeetingServicesQuery,
            },
          });
        },
        loadingMeetingServices: loading,
        loadingLocations,
        filtersForMeetingServicesQuery,
        setFiltersForMeetingServicesQuery,
        sortByForMeetingServicesQuery,
        setSortByForMeetingServicesQuery,
        selectedLocationIds,
        setSelectedLocationIds,
        organizationLocations,
        getMeetingServiceByIdQuery,
        serviceId,
        serviceFormOpen,
        setServiceFormOpen,
        openServiceFormWithId,
      }}
    >
      {children}
    </MeetingServicePageContext.Provider>
  );
};

export const useMeetingServicePageContext = (): MeetingServicesValue => {
  return useContext(MeetingServicePageContext);
};
