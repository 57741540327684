import { Upload } from '@robinpowered/ui-kit';
import { InboxOutlined } from '@ant-design/icons';
import { QuestionTypeContainer } from '../QuestionTypeContainer';
import { useQuestionContext } from 'components/ServiceRequest/components/QuestionTypes/contexts/QuestionContext';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { useQuestionFileUpload } from '../../hooks/useQuestionFileUpload';
const { Dragger } = Upload;

export const QuestionFileUpload = () => {
  const { t } = useTranslation('MeetingServiceRequest');
  const { prompt, required } = useQuestionContext();
  const {
    draggerProps,
    disabled,
    maxFileSizeMB,
    maxFileCount,
    validationError,
  } = useQuestionFileUpload();

  return (
    <QuestionTypeContainer
      title={prompt}
      required={required}
      error={validationError}
    >
      <DraggerContainer>
        <Dragger {...draggerProps} disabled={disabled}>
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">{t('file_upload.uploadText')}</p>
          <p className="ant-upload-hint">
            {t('file_upload.supportText', {
              maxSize: maxFileSizeMB,
              maxFiles: maxFileCount,
            })}
          </p>
        </Dragger>
      </DraggerContainer>
    </QuestionTypeContainer>
  );
};

const DraggerContainer = styled.div`
  display: flex;
  flex-direction: column;

  .ant-upload-drag {
    height: initial;
  }
`;
