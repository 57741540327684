import styled from '@emotion/styled';
import { Colors } from '@robinpowered/design-system';
import {
  TicketsListPageContextProvider,
  useTicketsListPageContext,
} from './contexts/TicketsListPageContext';
import { TicketsListTable } from 'components/TicketsListTable';
import { Layout } from '@robinpowered/ui-kit';
import { NavSidebar } from 'components/NavSidebar/NavSidebar';
import { ServiceDetailsSidebar } from 'components/ServiceDetailsSidebar/ServiceDetailsSidebar';
import { RejectCommentModal } from './contexts/RejectCommentModal';

export const TicketsListPageInner = () => {
  const { Content } = Layout;
  const { toastContextHolder, selectedTicketIdForApprovalProcess } =
    useTicketsListPageContext();

  return (
    <>
      {toastContextHolder}
      <Content>
        {selectedTicketIdForApprovalProcess?.status === 'rejecting' && (
          <RejectCommentModal />
        )}
        <ScrollWrapper>
          <Wrapper>
            <TicketsListTable />
          </Wrapper>
        </ScrollWrapper>
      </Content>
      <ServiceDetailsSidebar />
    </>
  );
};

export const TicketsListPage = () => {
  return (
    <Layout>
      <NavSidebar />
      <TicketsListPageContextProvider>
        <TicketsListPageInner />
      </TicketsListPageContextProvider>
    </Layout>
  );
};

const ScrollWrapper = styled.div`
  height: calc(100vh - 56px);
  background-color: ${Colors.Gray5};
  width: 100%;
  overflow-y: auto;
  padding: 32px;
`;

const Wrapper = styled.div`
  background-color: ${Colors.White0};
  width: 100%;
  height: 100%;
  border-radius: 8px;
  border: 1px solid ${Colors.Gray10};
  padding: var(--Space-Margin-marginXXS, 20px)
    var(--Space-Margin-marginMD, 20px);
`;
