import { createContext, useContext, FC, Dispatch, SetStateAction } from 'react';
import {
  GetMeetingServiceByIdForServiceRequestQuery,
  TicketQuestionMenuOption,
  TicketQuestionOption,
} from 'generated';
import {
  Answer,
  Answers,
  SelectType,
} from '../../../types/ServiceRequestTypes';

export type selectedServiceWithQuestions =
  | GetMeetingServiceByIdForServiceRequestQuery['getMeetingServiceById']['meetingService']
  | undefined;

export type ServiceQuestion = {
  prompt: string;
  required: boolean;
  multilineAnswer?: boolean;
  options?: (TicketQuestionOption | TicketQuestionMenuOption)[];
  id: string;
  chooseOnlyOneOption?: boolean;
  __typename?:
    | 'TicketQuestionExpectingChoices'
    | 'TicketQuestionExpectingMenuChoices'
    | 'TicketQuestionExpectingText'
    | 'TicketQuestionExpectingFiles';
};

type QuestionContextValue = {
  selectType: SelectType | undefined;
  answer: Answer | undefined;
  hasError: boolean;
  setHasError: (questionId: string, hasError: boolean) => void;
  setAnswers: Dispatch<SetStateAction<Answers>>;
} & ServiceQuestion;

const QuestionContext = createContext<QuestionContextValue>({
  prompt: '',
  required: false,
  options: [],
  id: '',
  selectType: undefined,
  answer: undefined,
  hasError: false,
  setHasError: () => {},
  setAnswers: () => {},
  __typename: 'TicketQuestionExpectingChoices',
});

export const QuestionContextProvider: FC<{
  question: ServiceQuestion;
  answers: Answers;
  hasError: boolean;
  setHasError: (questionId: string, hasError: boolean) => void;
  setAnswers: Dispatch<SetStateAction<Answers>>;
}> = ({ question, answers, hasError, setHasError, setAnswers, children }) => {
  const answer = answers.get(question.id);

  const selectType =
    question.__typename === 'TicketQuestionExpectingChoices' ||
    question.__typename === 'TicketQuestionExpectingMenuChoices'
      ? question.chooseOnlyOneOption
        ? 'single'
        : 'multi'
      : undefined;

  return (
    <QuestionContext.Provider
      value={{
        ...question,
        selectType,
        answer,
        hasError,
        setHasError,
        setAnswers,
      }}
    >
      {children}
    </QuestionContext.Provider>
  );
};

export const useQuestionContext = (): QuestionContextValue => {
  return useContext(QuestionContext);
};
