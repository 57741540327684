import { Typography, theme } from '@robinpowered/ui-kit';
import styled from '@emotion/styled';
import { useServiceDetailsSidebarContext } from '../contexts/ServiceDetailsSidebarContext';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { formatCurrency } from 'lib/utility';
import { FooterButtons } from './FooterButtons';

export const Footer = () => {
  const { useToken } = theme;
  const { token } = useToken();
  const { serviceDetails } = useServiceDetailsSidebarContext();
  const { t } = useTranslation('ServiceDetailsSidebar');
  const formattedTotalPrice = useMemo(() => {
    return serviceDetails && serviceDetails.totalPrice
      ? formatCurrency(serviceDetails.totalPrice)
      : null;
  }, [serviceDetails]);

  return (
    <Wrapper style={{ borderTop: `1px solid ${token.colorBorder}` }}>
      {formattedTotalPrice && (
        <Price>
          <Typography.Text>{t('footer.total')}</Typography.Text>
          <Typography.Text>{formattedTotalPrice}</Typography.Text>
        </Price>
      )}
      <FooterButtons />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  padding: 1rem;
  gap: 12px;
`;

const Price = styled.div`
  display: flex;
  justify-content: space-between;
  flex: 1;
`;
