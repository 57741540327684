import { Button } from '@robinpowered/ui-kit';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { useServiceDetailsSidebarContext } from '../contexts/ServiceDetailsSidebarContext';
import Close from '@robinpowered/ui-kit-icons/Close';
import Checkmark from '@robinpowered/ui-kit-icons/Checkmark';
import TrashOutline from '@robinpowered/ui-kit-icons/TrashOutline';
import { useTicketsListPageContext } from 'pages/TicketsListPage/contexts/TicketsListPageContext';
import {
  MeetingServiceRequestStatus,
  useDeleteOrCancelMeetingServiceRequestMutation,
} from 'generated';
import moment from 'moment';

export const FooterButtons = () => {
  const { t } = useTranslation('ServiceDetailsSidebar');
  const { serviceDetails, loading, ticketPermissions, refetchServiceDetails } =
    useServiceDetailsSidebarContext();
  const {
    handleApproveMeetingServiceRequest,
    setSelectedTicketIdForApprovalProcess,
    approvalProcessing,
    selectedTicketIdForApprovalProcess,
    toastMessage,
  } = useTicketsListPageContext();

  const [deleteOrCancelMeetingServiceRequest] =
    useDeleteOrCancelMeetingServiceRequestMutation({
      variables: { meetingServiceRequestId: serviceDetails?.id || '' },
    });

  const deleteOrCancelMeetingService = async () => {
    try {
      await deleteOrCancelMeetingServiceRequest();
      toastMessage('success', t('remove_service_request.result.success'));
      refetchServiceDetails();
    } catch (error) {
      toastMessage('error', t('remove_service_request.result.error'));
    }
  };

  if (!serviceDetails) return null;
  const isProcessingApproval =
    approvalProcessing &&
    selectedTicketIdForApprovalProcess?.id === serviceDetails?.id;

  const eventIsInPast = moment(
    serviceDetails.regardsEventAtSpace.eventStart
  ).isBefore(moment());

  const showApprovalButtons =
    ticketPermissions?.canApproveOrReject &&
    serviceDetails?.meetingServiceRequestStatus ===
      MeetingServiceRequestStatus.NeedsApproval;

  const showCancelButton =
    ticketPermissions?.canDeleteOrCancel &&
    !ticketPermissions.canUpdateStatus &&
    serviceDetails?.meetingServiceRequestStatus !==
      MeetingServiceRequestStatus.Cancelled &&
    !eventIsInPast;

  // const showEditButton =
  //   ticketPermissions?.canUpdateStatus &&
  //   serviceDetails?.meetingServiceRequestStatus ===
  //     MeetingServiceRequestStatus.NeedsApproval &&
  //   !eventIsInPast;

  const approvalButtons = (
    <>
      <Button
        loading={loading || isProcessingApproval}
        type="primary"
        onClick={() => {
          setSelectedTicketIdForApprovalProcess({
            id: serviceDetails.id,
            status: 'approving',
          });
          handleApproveMeetingServiceRequest({
            latestChangeId: serviceDetails.latestChangeId,
            meetingServiceRequestId: serviceDetails.id,
          });
        }}
        icon={<Checkmark />}
        style={{ width: '100%' }}
      >
        {t('footer.approval.approve')}
      </Button>
      <Button
        loading={loading || isProcessingApproval}
        danger
        onClick={() =>
          setSelectedTicketIdForApprovalProcess({
            id: serviceDetails.id,
            status: 'rejecting',
          })
        }
        icon={<Close />}
        style={{ width: '100%' }}
      >
        {t('footer.approval.reject')}
      </Button>
    </>
  );

  const cancelButton = (
    <Button
      danger
      icon={<TrashOutline />}
      onClick={deleteOrCancelMeetingService}
      style={{ width: '100%' }}
    >
      {t('footer.cancel')}
    </Button>
  );

  /* @TODO: Implement these button */
  // const reopenButton = <Button onClick={() => {}}>{t('footer.reopen')}</Button>;

  // const editButton = <Button onClick={() => {}}>{t('footer.edit')}</Button>;

  return (
    <ButtonGroup>
      {showApprovalButtons && approvalButtons}
      {showCancelButton && cancelButton}
      {/* {showReopenButton && reopenButton} */}
      {/* {showEditButton && editButton} */}
    </ButtonGroup>
  );
};

const ButtonGroup = styled.div`
  display: flex;
  gap: 8px;
`;
