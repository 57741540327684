import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import {
  Body,
  ButtonV4,
  Colors,
  UtilityButton,
} from '@robinpowered/design-system';
import BuildingSolid from '@robinpowered/icons/BuildingSolid';
import ChevronDownOutline from '@robinpowered/icons/ChevronDownOutline';
import ChevronUpOutline from '@robinpowered/icons/ChevronUpOutline';
import TrashOutline from '@robinpowered/icons/TrashOutline';
import { useManageBuildingDropdown } from './hooks';
import { MeetingServiceType } from 'components/ServiceForm/ServiceFormContainer';
import { useFormContext } from 'react-hook-form';
import { useSelectBuildingsContext } from '../../../../contexts';
import { FieldError } from 'components/ServiceForm/components';

type Props = {
  buildingId: string;
  onClick: () => void;
  isOpen: boolean;
};

export const Dropdown = ({ buildingId, onClick, isOpen }: Props) => {
  const { t } = useTranslation('ServiceSection');
  const {
    building,
    handleClickRemove,
    handleClickDropdown,
    totalSpacesSelectedInBuilding,
  } = useManageBuildingDropdown(buildingId);

  const { selectedBuildingsWithoutSpaces } = useSelectBuildingsContext();
  const { formState } = useFormContext<MeetingServiceType>();

  if (!building) return null;

  return (
    <Wrapper>
      <LeftWrapper>
        <UtilityButton
          icon={isOpen ? ChevronUpOutline : ChevronDownOutline}
          onClick={() => {
            onClick();
            handleClickDropdown();
          }}
        />
        <BuildingSolid size={16} /> {building.name}
        <Dot />
        {formState.errors.availableInSpacesByBuilding?.message &&
        selectedBuildingsWithoutSpaces.has(buildingId) ? (
          <div onClick={onClick} style={{ cursor: 'pointer' }}>
            <FieldError
              message={formState.errors.availableInSpacesByBuilding.message}
            />
          </div>
        ) : (
          <GrayText>
            {t(`location.select_location.dropdown.selected`, {
              count: totalSpacesSelectedInBuilding,
              total: building.totalSpaces,
            })}
          </GrayText>
        )}
      </LeftWrapper>

      <span title={t(`location.select_location.dropdown.delete`)}>
        <DeleteButton
          icon={() => <TrashOutline size={16} color={Colors.Gray60} />}
          onClick={handleClickRemove}
          variant="tertiary"
        />
      </span>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const LeftWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const GrayText = styled(Body.Regular)`
  color: ${Colors.Gray60};
`;

const Dot = styled.div`
  height: 3px;
  width: 3px;
  border-radius: 50%;
  background: ${Colors.Gray60};
`;

const DeleteButton = styled(ButtonV4)`
  height: 30px;
  width: 30px;
`;
