import { useTranslation } from 'react-i18next';
import { Avatar, Body, ButtonV4, Colors } from '@robinpowered/design-system';
import styled from '@emotion/styled';
import TrashOutline from '@robinpowered/icons/TrashOutline';
import { useSelectFacilitatorContext } from '../contexts';
import { buildThumbImage } from 'lib/images';

export const IndividualFacilitators = () => {
  const { t } = useTranslation('ServiceSection');
  const {
    individualFacilitator,
    removeIndividualFacilitator,
    groupFacilitator,
  } = useSelectFacilitatorContext();

  return (
    <FacilitatorWrapper hasGroup={!!groupFacilitator?.length}>
      {individualFacilitator?.map((individual, index) => {
        return (
          <Facilitator className="individualFacilitator" key={index}>
            <FacilitatorInfo>
              <Avatar
                name={individual?.name || ''}
                src={buildThumbImage(individual?.avatar) || ''}
              />

              <Body.Small>{individual?.name || 'Username'}</Body.Small>
            </FacilitatorInfo>

            <span title={t('facilitator.remove')}>
              <DeleteButton
                icon={() => <TrashOutline size={16} color={Colors.Gray60} />}
                onClick={() => removeIndividualFacilitator(index)}
                variant="tertiary"
              />
            </span>
          </Facilitator>
        );
      })}
    </FacilitatorWrapper>
  );
};

const FacilitatorWrapper = styled.div<{ hasGroup: boolean }>(
  (props) => `
    .individualFacilitator {
      border-bottom: 1px solid ${Colors.Gray20};
    }

    
    .individualFacilitator:last-of-type {
      border-bottom: ${props.hasGroup ? 1 : 0}px solid ${Colors.Gray20};
    }
  `
);

const Facilitator = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 16px;
`;

const FacilitatorInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const DeleteButton = styled(ButtonV4)`
  height: 30px;
  width: 30px;
`;
