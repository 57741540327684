import { Button, GlobalToken, theme } from '@robinpowered/ui-kit';
import { TFunction, useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf } from '@awesome.me/kit-cf858715fd/icons/classic/solid';
import { useServiceDetailsSidebarContext } from 'components/ServiceDetailsSidebar/contexts/ServiceDetailsSidebarContext';
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
  usePDF,
} from '@react-pdf/renderer';
import { Fragment, useEffect, useState } from 'react';
import {
  AbstractAnsweredQuestion,
  CompletedForm,
} from 'components/common/AnsweredQuestions/AnsweredQuestions';
import { formatCurrency } from 'lib/utility';
import { useTicketsListPageContext } from 'pages/TicketsListPage/contexts/TicketsListPageContext';
import { ServiceDetailsProps } from './useManageDownloadTicketPdf';
import { useManageDownloadTicketPdf } from './useManageDownloadTicketPdf';
import { useAmplitude } from 'contexts';
import { AmplitudeEvents } from 'types';

const useStyles = ({ token }: { token: GlobalToken }) => {
  return StyleSheet.create({
    page: {
      flexDirection: 'column',
      backgroundColor: token.colorWhite,
      padding: 40,
    },
    text: {
      fontSize: 12,
      color: token.colorText,
    },
    header: {
      marginBottom: 12,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    headerTitle: {
      fontSize: 16,
      color: token.colorText,
      fontWeight: 'bold',
    },
    section: {
      marginBottom: 24,
    },
    sectionTitle: {
      fontSize: 16,
      color: token.colorText,
      marginBottom: 16,
      fontWeight: 'bold',
    },
    row: {
      flexDirection: 'row',
      alignItems: 'flex-start',
      paddingVertical: 12,
      gap: 8,
    },
    tag: {
      minWidth: 125,
    },
    status: {
      padding: '4px 8px',
      backgroundColor: '#fafafa',
      borderRadius: 4,
      border: `1px solid ${token.colorBorder}`,
    },
    detailsSection: {
      borderRadius: 4,
      padding: 12,
      backgroundColor: '#fafafa',
      border: `1px solid ${token.colorBorder}`,
    },
    column: {
      flexDirection: 'column',
      padding: 12,
      backgroundColor: '#fafafa',
    },
    avatar: {
      width: 24,
      height: 24,
      backgroundColor: token.colorTextDisabled,
      borderRadius: 12,
      alignItems: 'center',
      justifyContent: 'center',
      marginRight: 8,
    },
    avatarText: {
      fontSize: 12,
      color: token.colorWhite,
    },
    userInfo: {
      flexDirection: 'row',
      alignItems: 'center',
    },
    questionTitle: {
      fontSize: 12,
      marginBottom: 4,
    },
    answeredText: {
      fontSize: 12,
      marginBottom: 4,
      color: token.colorTextSecondary,
    },
    answeredQuestionSection: {
      borderRadius: 4,
      display: 'flex',
      gap: 4,
      marginBottom: 18,
    },
    answeredQuestionContainer: {
      padding: 12,
      borderRadius: 4,
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      border: `1px solid ${token.colorBorder}`,
    },
    menuAnswer: {
      flexDirection: 'row',
      alignItems: 'center',
      gap: 16,
    },
    quantity: {
      fontSize: 12,
      display: 'flex',
      alignItems: 'center',
      gap: 4,
      color: token.colorTextSecondary,
    },
    quantityText: {
      fontSize: 12,
      color: token.colorTextSecondary,
      fontWeight: 'bold',
    },
    price: {
      fontSize: 12,
      color: token.colorText,
      fontWeight: 'bold',
    },
    detailColumn: {
      flexDirection: 'column',
      marginBottom: 16,
    },
    total: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginTop: 16,
    },
    footer: {
      position: 'absolute',
      bottom: 30,
      left: 40,
      right: 40,
      textAlign: 'left',
      fontSize: 8,
      paddingTop: 16,

      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      gap: 4,

      color: token.colorTextSecondary,
    },
  });
};

export const DownloadTicketPdf = () => {
  const { t } = useTranslation('common');
  const { trackEvent, flushEvents } = useAmplitude();
  const { t: tServiceDetailsSidebar } = useTranslation('ServiceDetailsSidebar');
  const { serviceDetails, loading } = useServiceDetailsSidebarContext();

  const { toastMessage } = useTicketsListPageContext();

  const { getServiceDetailsProps, totalPrice } = useManageDownloadTicketPdf();

  const [instance, updateInstance] = usePDF();

  const [downloadingPdf, setDownloadingPdf] = useState(false);

  /*
   * Proxy download because updateInstance has no callback to check instance updated
   * Need to check if the instance has been updated then simulate the download
   */
  useEffect(() => {
    if (downloadingPdf && instance.error) {
      toastMessage('error', t('error.something_went_wrong'));
      setDownloadingPdf(false);
    }
    if (downloadingPdf && instance.url && serviceDetails && !instance.loading) {
      /* Creates a temporary link to download then triggers the PDF download programmatically */
      const a = document.createElement('a');
      a.href = instance.url;
      a.download = `service-request-${
        serviceDetails.key
      }-${new Date().toISOString()}.pdf`;
      a.style.display = 'none';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      trackEvent(AmplitudeEvents.EXPORTED_MEETING_SERVICE_REQUEST_PDF, {
        application: 'services',
      });
      setDownloadingPdf(false);
    }
  }, [
    instance,
    downloadingPdf,
    serviceDetails,
    t,
    toastMessage,
    trackEvent,
    flushEvents,
  ]);

  const handleDownload = () => {
    setDownloadingPdf(true);

    updateInstance(
      <PDFDocument
        serviceDetailsProps={{
          ...getServiceDetailsProps(serviceDetails),
        }}
        completedForm={serviceDetails?.completedForm}
        totalPrice={totalPrice}
        quantityString={t('multi_answer.quantity')}
        translations={{
          serviceDetailsSidebar: tServiceDetailsSidebar,
          common: t,
        }}
      />
    );
  };

  if (loading || !serviceDetails || instance.error) {
    return null;
  }

  return (
    <Button
      type="text"
      shape="default"
      size="middle"
      icon={<FontAwesomeIcon icon={faFilePdf} />}
      onClick={handleDownload}
      loading={downloadingPdf}
      aria-label={tServiceDetailsSidebar('download_pdf')}
      title={tServiceDetailsSidebar('download_pdf')}
    />
  );
};

const ServiceDetails = ({
  status,
  requester,
  eventStartTime,
  location,
  eventTitle,
  category,
  serviceName,
  attendees,
  assignees,
  key,
}: ServiceDetailsProps) => {
  const { useToken } = theme;
  const { token } = useToken();
  const styles = useStyles({ token });

  return (
    <View style={styles.section}>
      <View style={styles.detailsSection}>
        {status && (
          <View style={styles.row}>
            <View style={styles.tag}>
              <Text style={styles.text}>{status.rowTitle}</Text>
            </View>
            <View style={styles.status}>
              <Text
                style={{
                  ...styles.text,
                  lineHeight: 1,
                }}
              >
                {status.rowValue}
              </Text>
            </View>
          </View>
        )}

        {requester && (
          <View style={styles.row}>
            <View style={styles.tag}>
              <Text style={styles.text}>{requester.rowTitle}</Text>
            </View>
            <View style={styles.userInfo}>
              {requester.rowValue.avatar ? (
                <Image src={requester.rowValue.avatar} style={styles.avatar} />
              ) : (
                <View style={styles.avatar}>
                  <Text style={styles.avatarText}>
                    {requester.rowValue.name.charAt(0)}
                  </Text>
                </View>
              )}
              <View>
                <Text style={styles.text}>{requester.rowValue.name}</Text>
                <Text style={styles.text}>{requester.rowValue.email}</Text>
              </View>
            </View>
          </View>
        )}

        {eventStartTime && (
          <View style={styles.row}>
            <View style={styles.tag}>
              <Text style={styles.text}>{eventStartTime.rowTitle}</Text>
            </View>
            <View>
              <Text style={styles.text}>{eventStartTime.rowValue.date}</Text>
              <Text style={styles.text}>{eventStartTime.rowValue.time}</Text>
            </View>
          </View>
        )}

        {location && (
          <View style={styles.row}>
            <View style={styles.tag}>
              <Text style={styles.text}>{location.rowTitle}</Text>
            </View>
            <View>
              <Text style={styles.text}>{location.rowValue.spaceName}</Text>
              <Text style={styles.text}>{location.rowValue.locationName}</Text>
            </View>
          </View>
        )}

        {eventTitle && (
          <View style={styles.row}>
            <View style={styles.tag}>
              <Text style={styles.text}>{eventTitle.rowTitle}</Text>
            </View>
            <View>
              <Text style={styles.text}>{eventTitle.rowValue}</Text>
            </View>
          </View>
        )}

        {category && (
          <View style={styles.row}>
            <View style={styles.tag}>
              <Text style={styles.text}>{category.rowTitle}</Text>
            </View>
            <View>
              <Text style={styles.text}>{category.rowValue}</Text>
            </View>
          </View>
        )}

        {serviceName && (
          <View style={styles.row}>
            <View style={styles.tag}>
              <Text style={styles.text}>{serviceName?.rowTitle}</Text>
            </View>
            <View>
              <Text style={styles.text}>{serviceName?.rowValue}</Text>
            </View>
          </View>
        )}

        {attendees && (
          <View style={styles.row}>
            <View style={styles.tag}>
              <Text style={styles.text}>{attendees.rowTitle}</Text>
            </View>
            <View>
              <Text style={styles.text}>{attendees.rowValue}</Text>
            </View>
          </View>
        )}

        {assignees && (
          <View style={styles.row}>
            <View style={styles.tag}>
              <Text style={styles.text}>{assignees?.rowTitle}</Text>
            </View>
            <View style={{ flex: 1, paddingRight: 12 }}>
              <Text style={styles.text}>
                <Text style={styles.text}>
                  {assignees?.rowValue.join(', ')}
                </Text>
              </Text>
            </View>
          </View>
        )}

        {key && (
          <View style={styles.row}>
            <View style={styles.tag}>
              <Text style={styles.text}>{key.rowTitle}</Text>
            </View>
            <View>
              <Text style={styles.text}>{key.rowValue}</Text>
            </View>
          </View>
        )}
      </View>
    </View>
  );
};

const AnsweredQuestions = ({
  completedForm,
  quantityString,
}: {
  completedForm: CompletedForm;
  quantityString: string;
}) => {
  const { useToken } = theme;
  const { token } = useToken();
  const styles = useStyles({ token });
  const renderQuestion = (abstractQuestion: AbstractAnsweredQuestion) => {
    switch (abstractQuestion.__typename) {
      case 'AnsweredTicketQuestionExpectingChoices': {
        const { question, choices } = abstractQuestion;

        return (
          <View style={styles.answeredQuestionSection}>
            <Text style={styles.questionTitle}>{question.prompt}</Text>
            {choices.map((choice, index) => (
              <View key={index} style={styles.answeredQuestionContainer}>
                <Text style={styles.text}>{choice.name}</Text>
              </View>
            ))}
          </View>
        );
      }
      case 'AnsweredTicketQuestionExpectingMenuChoices': {
        const { question, choices } = abstractQuestion;
        return (
          <View style={styles.answeredQuestionSection} wrap={false}>
            <Text style={styles.questionTitle}>{question.prompt}</Text>
            {choices.map((choice, index) => (
              <View key={index} style={styles.answeredQuestionContainer}>
                <Text style={styles.text}>{choice.option.name}</Text>
                <View style={styles.menuAnswer}>
                  <View style={styles.quantity}>
                    {choice.quantity && (
                      <>
                        <Text style={styles.text}>{quantityString}</Text>
                        <Text style={styles.quantityText}>
                          {choice.quantity}
                        </Text>
                      </>
                    )}
                  </View>
                  {choice.subtotalPrice && (
                    <Text style={styles.price}>
                      {formatCurrency(choice.subtotalPrice)}
                    </Text>
                  )}
                </View>
              </View>
            ))}
          </View>
        );
      }
      case 'AnsweredTicketQuestionExpectingText': {
        const { answer, question } = abstractQuestion;
        return (
          <View style={styles.detailColumn}>
            <Text style={styles.questionTitle}>{question.prompt}</Text>
            <View>
              <Text style={styles.answeredText}>{answer}</Text>
            </View>
          </View>
        );
      }
      default:
        return <></>;
    }
  };
  return (
    <View style={styles.section}>
      {completedForm?.questions?.map((question, index) => {
        return <Fragment key={index}>{renderQuestion(question)}</Fragment>;
      })}
    </View>
  );
};

type PDFDocumentProps = {
  serviceDetailsProps: ServiceDetailsProps;
  completedForm: CompletedForm;
  totalPrice?: {
    rowTitle: string;
    rowValue: string | null;
  } | null;
  quantityString: string;
  translations: Record<string, TFunction>;
};

const PDFDocument = ({
  serviceDetailsProps,
  completedForm,
  totalPrice,
  quantityString,
  translations,
}: PDFDocumentProps) => {
  const { useToken } = theme;
  const { token } = useToken();
  const styles = useStyles({ token });

  const { location, eventStartTime: eventTime } = serviceDetailsProps;
  const footerLocation = `${location?.rowTitle}: ${location?.rowValue.locationName}, ${location?.rowValue.spaceName}`;
  const footerEventTime = `${eventTime?.rowTitle}: ${eventTime?.rowValue.date}, ${eventTime?.rowValue.time}`;

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View fixed style={styles.header}>
          {serviceDetailsProps.eventTitle?.rowValue &&
          serviceDetailsProps.serviceName?.rowValue ? (
            <Text style={styles.headerTitle}>
              {translations.serviceDetailsSidebar('title', {
                serviceName: serviceDetailsProps.serviceName?.rowValue,
                eventTitle: serviceDetailsProps.eventTitle?.rowValue,
                key: serviceDetailsProps.key?.rowValue,
              })}
            </Text>
          ) : (
            <Text style={styles.headerTitle}>
              {translations.serviceDetailsSidebar('title_no_event', {
                serviceName: serviceDetailsProps.serviceName?.rowValue,
                key: serviceDetailsProps.key?.rowValue,
              })}
            </Text>
          )}
          <Text
            render={({ pageNumber, totalPages }) => {
              return (
                <Text style={styles.headerTitle}>
                  {translations.common('pageNumber', {
                    pageNumber,
                    totalPages,
                  })}
                </Text>
              );
            }}
          />
        </View>
        {ServiceDetails({
          ...serviceDetailsProps,
        })}
        {AnsweredQuestions({
          completedForm,
          quantityString,
        })}
        {totalPrice && (
          <View style={styles.total}>
            <Text style={styles.text}>{totalPrice.rowTitle}</Text>
            <Text style={styles.text}>{totalPrice.rowValue}</Text>
          </View>
        )}

        <View style={styles.footer} fixed>
          <Text>{footerLocation}</Text>
          {/* Tiny dot component */}
          <View
            style={{
              width: 4,
              height: 4,
              backgroundColor: token.colorBorder,
              borderRadius: 2,
              display: 'flex',
            }}
          />
          <Text>{footerEventTime}</Text>
        </View>
      </Page>
    </Document>
  );
};
