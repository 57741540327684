import { useMemo } from 'react';
import {
  GetBuildingsWhereUserCanManageMeetingServicesForFormQuery,
  GetLocationsByOrgIdQuery,
  useGetBuildingsWhereUserCanManageMeetingServicesForFormQuery,
  useGetLocationsByOrgIdQuery,
} from 'generated';
import { useAuthContext } from 'contexts';

type UseManageMeetingServicesLocationType = {
  organizationLocations: NonNullable<
    GetLocationsByOrgIdQuery['getOrganizationById']
  >['locations'];
  locationsUserCanManage: NonNullable<
    GetBuildingsWhereUserCanManageMeetingServicesForFormQuery['listBuildingsWhereUserCanManageMeetingServices']
  >['buildings'];
  loading: boolean;
};

export const useManageMeetingServicesLocations =
  (): UseManageMeetingServicesLocationType => {
    const { currentOrg, loading: authLoading } = useAuthContext();
    const {
      data: buildingData,
      loading: loadingMeetingServiceManageBuildings,
    } = useGetBuildingsWhereUserCanManageMeetingServicesForFormQuery({
      variables: {},
      skip: authLoading,
    });
    const { data: orgData, loading: loadingOrg } = useGetLocationsByOrgIdQuery({
      variables: {
        id: currentOrg?.id || '',
      },
      skip: authLoading,
    });

    const organizationLocations = useMemo(() => {
      return orgData?.getOrganizationById?.locations || [];
    }, [orgData?.getOrganizationById?.locations]);

    const locationsUserCanManage = useMemo(() => {
      return (
        buildingData?.listBuildingsWhereUserCanManageMeetingServices
          ?.buildings || []
      );
    }, [buildingData]);

    return {
      organizationLocations,
      locationsUserCanManage,
      loading:
        loadingMeetingServiceManageBuildings || loadingOrg || authLoading,
    };
  };
