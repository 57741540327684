import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetDownloadUrlForTicketFileLazyQuery } from 'generated/graphql';
import { useTicketsListPageContext } from 'pages/TicketsListPage/contexts/TicketsListPageContext';
import { Sentry } from 'lib/sentry';
import { DEFAULT_TOAST_ERROR_SECONDS } from 'lib/utility';
import { parse } from '@tinyhttp/content-disposition';

import saveAs from 'file-saver';

export type TicketIdentifier = { id: string } | { key: string };

export const useFileDownload = () => {
  const { t } = useTranslation('ServiceDetailsSidebar');
  const [downloadingFile, setDownloadingFile] = useState(false);
  const { toastMessage } = useTicketsListPageContext();

  const [getDownloadUrlForTicketFile] = useGetDownloadUrlForTicketFileLazyQuery(
    {
      fetchPolicy: 'network-only',
    }
  );

  /*
    Because of browser security and some known browser bugs, we need to simulate the download
    to ensure it works consistently across all browsers.
    @see https://bugzilla.mozilla.org/show_bug.cgi?id=874009
  */
  const simulateDownload = async (
    fileUrl: string,
    fileId: string
  ): Promise<{ fileName?: string; success: boolean }> => {
    try {
      const response = await fetch(fileUrl, {
        credentials: 'include',
        headers: {},
      });

      const blob = await response.blob();
      const disposition = parse(
        response.headers.get('content-disposition') || ''
      );
      const fileName = (disposition.parameters.filename as string) || fileId;
      saveAs(blob, fileName);
      return { fileName, success: true };
    } catch (error) {
      Sentry.captureException(error);
      return { success: false };
    }
  };

  const downloadFile = useCallback(
    async ({
      fileId,
      ticketIdentifier,
      fileName,
    }: {
      fileId: string;
      ticketIdentifier: TicketIdentifier;
      fileName?: string | null;
    }) => {
      setDownloadingFile(true);

      try {
        const response = await getDownloadUrlForTicketFile({
          variables: {
            fileId,
            ticketIdentifier:
              'id' in ticketIdentifier
                ? { id: ticketIdentifier.id }
                : { key: ticketIdentifier.key },
          },
        });

        const fileUrl = response.data?.getEphemeralDownloadUrlForTicketFile.url;

        if (fileUrl) {
          simulateDownload(fileUrl, fileId).then((response) => {
            if (response.success) {
              toastMessage(
                'success',
                t('file_answer.success', { fileName: response.fileName })
              );
            } else {
              toastMessage('error', t('file_answer.error', { fileName }));
            }
          });
        } else {
          if (
            response.error?.graphQLErrors.some(
              (err) => err.extensions.code === 'NOT_FOUND'
            )
          ) {
            toastMessage(
              'error',
              t('file_answer.file_not_found', { fileName }),
              DEFAULT_TOAST_ERROR_SECONDS
            );
          } else {
            toastMessage('error', t('file_answer.error', { fileName }));
          }
          Sentry.captureException(response.error);
        }
      } catch (err) {
        toastMessage(
          'error',
          t('file_answer.error', { fileName }),
          DEFAULT_TOAST_ERROR_SECONDS
        );
        Sentry.captureException(err);
      } finally {
        setDownloadingFile(false);
      }
    },
    [getDownloadUrlForTicketFile, t, toastMessage]
  );

  return {
    downloadFile,
    downloadingFile,
  };
};
